import React, { Component } from 'react'

import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import loginActions from '../../actions/loginActions'
import { validateEmail } from '../../utils/dataUtils';

import View from './verifyInfoView'

class VerifyInfoContainer extends Component {

    constructor(props) {
        super(props)

        if (!props.emailUni || !props.phone) {
            props.history.replace('contact-info')
        }

        this.state = {
            codeUni: '',
            codePhone: '',
            isValid: false,
            loading: false,
            emailUni: '',
            phone: '',
            emailUniEdit: false,
            phoneEdit: false,
            isEmailEnabled: 'disabled',
            isPhoneEnabled: 'disabled',
            emailCheck: false,
            phoneCheck: false,
            phoneClick: false,
            emailClick: false,
            messageEmail: '',
            messagePhone: '',
        }

        props.loginActions.initVerifyInfo()
    }

    emailResponse = () => {
        if (this.props.emailVerify === true) {
            this.setState({ emailCheck: true, emailClick: true, isEmailEnabled: 'verify', messageEmail: '' })
        } else {
            this.setState({ isEmailEnabled: 'disabled', emailCheck: false, emailClick: true, messageEmail: 'Invalid code, try again' })
        }
    }

    phoneResponse = () => {
        if (this.props.phoneVerify === true) {
            console.log(this.props.phoneVerify, "phone verifi")
            this.setState({ isPhoneEnabled: 'verify', phoneCheck: true, phoneClick: true, messagePhone: '' })
        } else {
            this.setState({ isPhoneEnabled: 'disabled', phoneCheck: false, phoneClick: true, messagePhone: 'Invalid code, try again' })
        }
    }

    emailVerify = async () => {
        const { codeUni } = this.state;
        if (codeUni !== "") {
            let flag = "email"
            this.props.loginActions.verifyContactInfo(codeUni, flag)
            await this.emailResponse()
        }
    }

    phoneVerify = async () => {
        const { codePhone } = this.state;
        if (codePhone !== "") {
            let flag = "phone"
            this.props.loginActions.verifyContactInfo(codePhone, flag)
            await this.phoneResponse()
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isVerified) {
            // this.props.history.replace('/survey/basics')
            this.props.history.replace('/app-redirect')
        }
        else if (this.state.loading && nextProps.error) {
            this.setState({ loading: false })
        }

        return true
    }

    onNext = () => {
        if (this.props.emailVerify && this.props.phoneVerify) {
            // this.props.history.goBack()

            this.setState({ loading: true })
            // this.props.loginActions.verifyContactInfo(codeUni, codePhone)
            this.props.history.push('/app-redirect')
        } else {
        }
    }

    onEditBtn = () => {
        this.props.history.push('/edit-info')
    }

    shouldValidate = (codeUni, codePhone) => {
        return codeUni.length > 0 && codePhone.length > 0
    }

    onChangeUniCode = (event) => {
        let value = event.target.value
        if (value.length > 0) {
            let codeUni = value
            // let isValid = this.shouldValidate(codeUni, this.state.codePhone)
            this.setState({ codeUni, isEmailEnabled: "enabled" })
        }
        else {
            this.setState({ isEmailEnabled: "disabled" })
        }
    }

    onChangePhoneCode = (event) => {
        let value = event.target.value
        if (value.length > 0) {
            let codePhone = value
            // let isValid = this.shouldValidate(this.state.codeUni, codePhone)
            this.setState({ codePhone, isPhoneEnabled: 'enabled' })
        }
        else {
            this.setState({ isPhoneEnabled: "disabled" })
        }
    }

    componentDidMount() {
        const { emailUni, phone } = this.props
        this.props.loginActions.checkContactSync()
        this.setState({
            emailUni: emailUni,
            phone: phone,
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.emailUni !== this.props.emailUni || prevProps.phone !== this.props.phone) {
            this.setState({
                emailUni: this.props.emailUni,
                phone: this.props.phone,
            })
        }
        // if (prevProps.emailVerify !== this.props.emailVerify || prevProps.phoneVerify !== this.props.phoneCheck) {
        //     console.log("Uso")
        //     this.setState({
        //         emailCheck: this.props.emailVerify,
        //         phoneCheck: this.props.phoneVerify,
        //     })
        // }

    }

    phoneRegex = phone => phone.replace(/[^+\d]+/g, '');

    onChangeInfo = event => {
        let { name, value } = event.target
        if (name === "phone") {
            value = this.phoneRegex(value);
        }
        this.setState({ [name]: value })
    }

    edit = name => {
        let str = name + 'Edit';
        this.setState({ [str]: true })
    }



    // enterPress = event => {
    //     if (event.key === "Enter")
    //         this.saveChanges(event.target.name)
    // }

    // saveChanges = flag => {
    //     const { phoneId,
    //         emailUniId } = this.props;

    //     const { emailUni, phone } = this.state;

    //     let body = {};

    //     switch (flag) {
    //         case "emailUni":
    //             if (validateEmail(emailUni)) {
    //                 body = {
    //                     "email1": {
    //                         "id": emailUniId,
    //                         "value": emailUni
    //                     },

    //                 }
    //             } else {
    //                 alert("Email is not valid")
    //             }
    //             break;
    //         case "phone":
    //             body = {
    //                 "phoneNumber": {
    //                     "id": phoneId,
    //                     "value": "+1" + phone
    //                 },
    //             };
    //             break;
    //         default: console.log("default");
    //     }

    //     this.props.loginActions.contactInfoChange(body)

    //     let str = flag + 'Edit';
    //     this.setState({ [str]: false })
    // }

    render() {
        const { phoneClick, emailClick, isValid, loading, emailUni, phone, emailUniEdit, phoneEdit, isEmailEnabled, isPhoneEnabled, messageEmail, messagePhone } = this.state
        return <View
            onChangePhoneCode={this.onChangePhoneCode}
            onChangeUniCode={this.onChangeUniCode}
            onChangeAltCode={this.onChangeAltCode}
            onChangeInfo={this.onChangeInfo}
            onNext={this.onNext}
            edit={this.edit}
            isValid={isValid}
            emailUniEdit={emailUniEdit}
            phoneEdit={phoneEdit}
            emailUni={emailUni}
            phone={phone}
            loading={loading}
            phoneClick={phoneClick}
            emailClick={emailClick}
            isEmailEnabled={isEmailEnabled}
            isPhoneEnabled={isPhoneEnabled}
            emailVerify={this.emailVerify}
            phoneVerify={this.phoneVerify}
            emailCheck={this.props.emailVerify}
            phoneCheck={this.props.phoneVerify}
            onEditBtn={this.onEditBtn}
            messageEmail={messageEmail}
            messagePhone={messagePhone}
        />
    }
}

const mapStateToProps = (state) => ({
    emailUni: state.data.email1.value,
    emailUniId: state.data.email1.id,
    phoneVerify: state.data.phoneVerify,
    emailVerify: state.data.emailVerify,
    phone: state.data.phoneNumber.value,
    phoneId: state.data.phoneNumber.id,
    isVerified: state.data.isVerified,
    error: state.status.contactInfoVerifyError,
})

const mapDispatchToProps = (dispatch) => ({
    loginActions: bindActionCreators(loginActions, dispatch),


})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyInfoContainer)