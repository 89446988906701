import React, { Component } from 'react'

import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import loginActions from '../../actions/loginActions'
import { validateEmail } from '../../utils/dataUtils'
import View from './contactInfoView'

class ContactInfoContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            emailUni: '',
            phone: '',
            isValid: false,
            loading: false,
            countryCode: '',
        }

        props.loginActions.initContactInfo()
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isSent) {
            this.props.history.replace('/verify-info')
        }
        else if (this.state.loading && nextProps.error) {
            this.setState({ loading: false })
        }

        return true
    }

    onNext = async () => {
        if (this.state.isValid) {

            // this.props.history.goBack()
            const { emailUni, phone, countryCode } = this.state
            this.setState({ loading: true })

            let number = phone

            await this.props.loginActions.sendContactInfo(emailUni, number, countryCode)

        }
    }

    handleInputChange = (telNumber, selectedCountry) => {
        let value = telNumber;
        let countryCode = selectedCountry.dialCode;

        let phone = this.phoneRegex(value);
        this.setState({ phone, countryCode })

        if (value.length > 2) {
            let isValid = this.shouldValidate(this.state.emailUni, phone)
            this.setState({ isValid })
        } else {
            this.setState({ isValid: false })
        }
    }


    shouldValidate = (emailUni, phone) => {
        return validateEmail(emailUni) && phone.length > 0
    }

    onChangeUniEmail = (event) => {
        let value = event.target.value
        let email = value.toLowerCase();
        console.log(email, "email")
        let isValid = this.shouldValidate(email, this.state.phone)
        this.setState({ emailUni: email, isValid }) // FOR TEST 
    }

    phoneRegex = phone => phone.replace(/[^+\d]+/g, '');

    onChangePhone = (event) => {
        let value = event.target.value
        if (value.length > 0) {
            let phone = this.phoneRegex(value);
            let isValid = this.shouldValidate(this.state.emailUni, phone)
            this.setState({ phone, isValid })
        }
        else {
            this.setState({ isValid: false })
        }
    }

    // redirect = () => {
    //     const { emailUni } = this.state;
    //     this.props.history.push({
    //         pathname: '/login',
    //         email: emailUni
    //     })
    // }

    handleError = error => {
        let errorMessage1 = "";
        let errorMessage2 = "";

        if (error.conflicts.length === 2) {
            errorMessage1 = `This email is already registered to a coo-e user.`;
            errorMessage2 = "This number is already registered";
        } else {
            let n = error.conflicts[0].value.includes("com")
            if (n) {
                errorMessage1 = `This email is already registered to a coo-e user.`;
                errorMessage2 = "";
            } else {
                errorMessage1 = ``;
                errorMessage2 = "This number is already registered";
            }

        }
        console.log(errorMessage1)
        let errors = {
            errorMessage1,
            errorMessage2
        }
        return errors
    }

    componentDidMount() {
        this.props.loginActions.clearVerify()
    }

    render() {
        const { isValid, loading, emailUni } = this.state
        const { error } = this.props;
        let err = {};
        if (error) {
            // this.redirect()
            err = this.handleError(error)
        }
        console.log(err, "err")
        return <View
            onNext={this.onNext}
            isValid={isValid}
            onChangeUniEmail={this.onChangeUniEmail}
            onChangeAltEmail={this.onChangeAltEmail}
            onChangePhone={this.onChangePhone}
            loading={loading}
            err={err}
            email={emailUni}
            handleInputChange={this.handleInputChange}
        />
    }
}

const mapStateToProps = (state) => ({
    isSent: state.data.isSent,
    error: state.status.contactInfoSendError,
})

const mapDispatchToProps = (dispatch) => ({
    loginActions: bindActionCreators(loginActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoContainer)