import React, { Component } from 'react'
import hmtlParser from 'html-react-parser'
import icon from '../../../assets/svg/user.svg';
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import interestsActions from 'actions/interestsActions'
import activitiesActions from 'actions/activitiesActions'
import { activitiesEndpoints, routes } from 'configs/endpoints'
import './interestsActivities.css';
import SingleInterest from './singleInterest';

class InterestLists extends Component {

  exampleText = `<span>We’ve generated a list of activities and organizations based off of your previous responses. While looking at the activities below; Please think of all the interests that you would like to do with a group of friends.</span>
  </br>
  </br>
  <span>[e.g. “The Vector” would make you think of “Journalism” or “ACM SIGGRAPH” would make you think of “Art” or “Animation”]</span>
  </br>
  </br?
  <span>After thinking about those interests, type them into the search bar below one by one.</span>`


  state = {
    isVisible: false,
    interestsSearch: [],
    interestsData: [],
    interestIds: [],
    exampleInterests: [],
    newItems: [],
  }

  componentDidMount() {
    this.props.interestsActions.searcInterests("");
    this.props.interestsActions.getUserInterests();
    let interests = JSON.parse(JSON.stringify(this.props.interests));
    this.setState({ interestsData: interests })
    this.setState({
      exampleInterests: this.props.savedInterests,
    })


  }

  componentDidUpdate(prevProps) {
    if (prevProps.interests.length !== this.props.interests.length) {
      this.setState({ interestsData: this.props.interests })
    }
    if (this.state.interestsSearch.length !== this.props.searchedInterests.length) {
      this.setState({
        interestsSearch: this.props.searchedInterests
      })
    }
  }


  follow = (item) => {
    let interests = this.state.interestIds;
    let data = this.state.interestsData;
    let check = false;

    data.map(dataItem => {
      if (item.value === dataItem.value) {
        check = true;
      }
    })

    if (!check) {
      interests.push(item.id);
      data.push(item);
    }

    this.setState({
      interestData: data,
      interestIds: interests,
      searchValue: "",
      isVisible: false,
    })
  }

  send = () => {
    let data = {
      interestIds: this.state.interestIds
    }
    this.props.interestsActions.followInterest(data)
  }

  // renderMyInterests = data => {
  //   let a = data.map(item => {
  //     return (
  //       <SingleInterest name={item.value} />
  //     )
  //   })
  //   return a
  // }

  createNew = (event) => {
    if (event.key === "Enter") {
      if (this.props.searchedInterests.length === 0) {
        this.addInterests(event.target.value)
      }
    }

  }

  renderSolution = (searchedInterests) => {

    const { interestData } = this.state;

    let b = searchedInterests.map(item => {
      let check = false;
      this.state.interestsData.map(data => {
        if (item.value === data.value) {
          check = true;
        }
      })
      if (check) {
        return (
          <div
            style={{
              width: '748px',
              cursor: 'pointer',
              padding: '10px',
              border: 'solid 1px #e1e1e1',
              backgroundColor: '#e1e1e1',
              backgroundColor: 'silver'
            }}
          >{item.value}</div>
        )
      } else {
        return (
          <div
            onClick={() => this.follow(item)}
            style={{
              width: '748px',
              cursor: 'pointer',
              padding: '10px',
              border: 'solid 1px #e1e1e1',
              backgroundColor: '#e1e1e1',
            }}
          >{item.value}</div>
        )
      }

    })
    let c = <div style={{ position: 'absolute', zIndex: 1, }}>{b}</div>
    return c;
  }
  search = (event) => {
    let value = event.target.value;
    if (value === "") {
      this.setState({ isVisible: false, searchValue: value })
    } else {
      this.setState({ isVisible: true, searchValue: value })
    }
    this.props.interestsActions.searcInterests(value)
    // let interests = JSON.parse(JSON.stringify(this.props.interests));
    // this.setState({ interestsSearch: interests })
  }


  onNext = async () => {
    this.send()
    // this.props.history.replace(routes.desktop)
    await this.props.history.push('addinterestsList');
  }

  onBack = () => {
    this.props.history.replace("/survey/activities/interests")
  }

  addInterests = async (value) => {
    let check = false;
    let a = await this.state.interestsSearch;

    a.map(item => {
      if (item.value === value) {
        this.follow(item);
        let data = {
          interestIds: [item.id]
        }
        this.props.interestsActions.followInterest(data)
        check = true;
      }
    })

    if (!check) {
      this.props.interestsActions.createInterests(value)
    }
    // await this.follow(value)
  }

  deleteInterest = async (id) => {
    this.props.interestsActions.unFollowInterests(id);
    await this.setState({ interestsData: this.props.interests })
  }


  render() {
    let { searchedInterests } = this.props;
    console.log(searchedInterests, "searchedInterests")
    let { interestsData, isVisible, searchValue, exampleInterests } = this.state;
    console.log(this.state.interestsSearch, "in data")
    return (
      <div>
        <div className="activity-table-example" style={{ width: "45%", margin: '0 auto' }}>
          <p style={{ fontFamily: 'Helvetica', fontSize: '16px', lineHeight: '21px', letterSpacing: '-0.4px' }}><b>{hmtlParser(this.exampleText)}</b></p>
        </div>
        <div style={{ width: '50%', margin: '0 auto', padding: '20px', textAlign: 'center', marginBottom: '60px' }}>
          {exampleInterests.length ? exampleInterests.map(item => (
            <span className="exampleInterest" onClick={() => this.addInterests(item)}>{item}</span>
          )) : null}
        </div>
        <div style={{ clear: 'both' }} />
        <div style={{ width: '1161px', height: '2px', border: 'solid 1px #979797', margin: '60px auto', marginTop: '80px' }} />
        <div style={{ width: '748px', margin: '0 auto' }}>
          <input type="text" placeholder="Search Interests" style={{
            width: '748px',
            height: '40px',
            padding: '10px',
            borderRadius: '9px',
            border: 'solid 1px #e1e1e1',
            margin: '0 auto',
            backgroundColor: '#e1e1e1'
          }}
            value={searchValue}
            onChange={(event) => this.search(event)}
            onKeyPress={(event) => this.createNew(event)}
          />
          {
            isVisible ?
              this.renderSolution(searchedInterests) : null
          }
        </div>
        <div style={{ width: '45%', margin: '20px auto' }}>
          {interestsData ?
            interestsData.map(item => (
              <SingleInterest
                name={item.value}
                id={item.id}
                deleteInterest={this.deleteInterest}
              />
            )) : null
          }
        </div>
        <div style={{ width: "100%", height: '200px' }} />
        <div className='activity-button-container'>
          <div className={"rounded-button bcolor-pink"} onClick={this.onBack}>
            <span className="rounded-button-text">Back</span>
          </div>
          <div className={"rounded-button bcolor-pink"} onClick={this.onNext}>
            <span className="rounded-button-text">Next</span>
          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  interests: state.interests.interests,
  searchedInterests: state.interests.searchedInterests,
  savedInterests: state.interests.savedInterests
})

const mapDispatchToProps = (dispatch) => ({
  interestsActions: bindActionCreators(interestsActions, dispatch),
  activitiesActions: bindActionCreators(activitiesActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(InterestLists)