import React, { Component } from 'react'
import { connect } from "react-redux"
import icon from '../../../assets/svg/user.svg';
import { bindActionCreators } from 'redux'
import interestsActions from 'actions/interestsActions'
import { activitiesEndpoints, routes } from 'configs/endpoints'

class AddInterests extends Component {
  state = {
    interestsData: [],
    interestName: '',
  }

  exampleText = `If there are any interest tags where you would like to meet with other people; and that you’ve yet to add the the list below, please type them into the search bar and let us know!`

  componentDidMount() {
    this.load();
  }

  load = async () => {
    // this.props.interestsActions.getUserInterests();
    let interests = await JSON.parse(JSON.stringify(this.props.interests));
    await this.setState({ interestsData: interests })
  }

  changeName = (event) => {
    this.setState({ interestName: event.target.value })
  }

  onEnterPress = (event) => {
    if (event.key === "Enter") {
      this.addInterests(this.state.interestName)
      this.setState({ interestName: "" })
    }
  }

  onNext = () => {
    this.props.history.replace(routes.desktop)
  }

  onBack = () => {
    this.props.history.replace("/survey/activities/interestsList")
  }


  componentDidUpdate(prevProps) {
    if (prevProps.interests.length !== this.props.interests.length) {
      this.setState({ interestsData: this.props.interests })
    }
  }

  addInterests = (value) => {
    this.props.interestsActions.createInterests(value)
  }

  render() {
    let { interestsData, interestName } = this.state;

    return (
      <div>
        <div className="activity-table-example" style={{ width: "750px", margin: '0 auto', marginBottom: '40px' }}>
          <p style={{ fontFamily: 'Helvetica', fontSize: '16px', lineHeight: '21px', letterSpacing: '-0.4px' }}><b>{this.exampleText}</b></p>
        </div>
        <div style={{ width: "750px", margin: '0 auto' }}>
          <input type="text" placeholder="Search Interests" style={{
            width: '748px',
            height: '40px',
            padding: '10px',
            borderRadius: '9px',
            border: 'solid 1px #e1e1e1',
            margin: '0 auto',
            backgroundColor: '#e1e1e1'
          }}
            onChange={(event) => this.changeName(event)}
            onKeyPress={(event) => this.onEnterPress(event)}
            value={interestName}
          />
        </div>
        <div style={{ width: '45%', margin: '20px auto' }}>
          {interestsData ?
            interestsData.map(item => (
              <span style={{ margin: '2px', color: 'white', background: '#363fa2', float: 'left', border: 'solid 1px #363fa2', borderRadius: '20px', textAlign: 'center' }}>
                <span style={{ fontSize: 25, marginRight: '10px', marginLeft: '10px', float: 'left' }}>&#9829;</span>
                <span style={{ position: 'relative', top: '7px', marginRight: '30px' }}>{item.value}</span></span>
            )) : null
          }

        </div>
        <div style={{ width: "100%", height: '300px' }} />
        <div className='activity-button-container'>
          <div className={"rounded-button bcolor-pink"} onClick={this.onBack}>
            <span className="rounded-button-text">Back</span>
          </div>
          <div className={"rounded-button bcolor-pink"} onClick={this.onNext}>
            <span className="rounded-button-text">Next</span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  interests: state.interests.interests,
})

const mapDispatchToProps = (dispatch) => ({
  interestsActions: bindActionCreators(interestsActions, dispatch),
  // activitiesActions: bindActionCreators(activitiesActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddInterests)
