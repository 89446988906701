const hostname = 'https://tell-us.coo-e.com'
// const hostname = 'http://localhost:3000' 
// const apiHostname = 'https://dev-api.coo-e.com'
const demoHostname = "https://demo-api.coo-e.com"
const stgHostname = "https://stg-api.coo-e.com"
const devHostname = "https://dev-api.coo-e.com"


const apiHostname = demoHostname;

const cmsEndpoint = apiHostname + '/'

const activitiesEndpoints = {
    campus: 'campus',
    campusQuiz: 'campusQuiz',
    campusSelector: 'campusSelector',
    classRelated: 'classRelated',
    classRelatedQuiz: 'classRelatedQuiz',
    classSelector: 'classRelatedSelector',
    work: 'work',
    workQuiz: 'workQuiz',
    workSelector: 'workSelector',
    interestGroups: 'interestGroups',
    interestGroupsQuiz: 'interestGroupsQuiz',
}
const routes = {
    desktop: 'desktop'
}

const Endpoints = {
    hostname,
    auth0Callback: hostname + '/login',
    // Login
    register: cmsEndpoint + 'users/register',
    sendContactInfo: cmsEndpoint + 'verify/send',
    verifyContactInfo: cmsEndpoint + 'verify/validate',
    languages: cmsEndpoint + 'enum/demographics/languages',
    majors: cmsEndpoint + 'enum/education/majors',

    syncContacts: (id) => cmsEndpoint + 'users/' + id + '/contacts',

    register: cmsEndpoint + 'users/register',
    login: cmsEndpoint + 'users/login',
    verify: cmsEndpoint + 'users/verify',
    editContactInfo: cmsEndpoint + 'verify/send',

    userInfo: (id) => cmsEndpoint + 'users/' + id,
    activityGroups: (id) => cmsEndpoint + 'users/' + id + '/activity/members',
    contactGroups: (id) => cmsEndpoint + 'users/' + id + '/groups',
    attributeGroups: (id) => cmsEndpoint + 'users/' + id + '/attribute/contacts',
    bucketGroups: (id) => cmsEndpoint + 'users/' + id + '/bucket/contacts',
    demographics: (id) => cmsEndpoint + 'users/' + id + '/demographics',
    activities: (id) => cmsEndpoint + 'users/' + id + '/activities',
    fetchContacts: (id) => cmsEndpoint + 'users/' + id + '/contacts',
    recommendTeeUp: (id) => cmsEndpoint + 'users/' + id + '/recommend/TeeUp',
    teeups: (id) => cmsEndpoint + id + '/teeups',
    postPayment: (id) => cmsEndpoint + 'users/' + id + '/payment',
    desktopState: (id) => cmsEndpoint + 'users/' + id + '/desktop/state',
    editContact: (id) => cmsEndpoint + 'users/' + id + '/phone/contact',

    searchInterests: (value) => cmsEndpoint + 'interests?query=' + value,
    followInterests: (id) => cmsEndpoint + 'users/' + id + '/interests',
    unFollowInterests: (id, interestsId) => cmsEndpoint + 'users/' + id + '/interests/' + interestsId,
    interestsList: (id) => cmsEndpoint + 'users/' + id + '/interests',
    createInterests: cmsEndpoint + 'interests',
}

export { Endpoints, activitiesEndpoints, routes }
