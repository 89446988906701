import React from 'react'
import hmtlParser from 'html-react-parser'
import VideoPopUp from './videoPopUp'

import BoxContacts from './boxContacts'
import BoxDesktop from './boxDesktop'
import BoxActivities from './boxActivities'
import BucketItem from './bucketItem'
import ContactModal from './contactModal'

import './desktopStyles.css'

import tabContacts from 'assets/svg/user.svg'
import tabActivities from 'assets/svg/tag.svg'

import tagIcons from './tagIcons'
import CreateTeeup from 'components/createTeeup/createTeeupContainer'
import CreateTeeupModal from './createTeeupModal'

let cont = [];
let obj = {};


const HintView = ({ tag, attributeQuestion, bucket, pageType }) => {

    let content = null
    if (tag) {
        content = <div className='social-desktop-hint'>
            Please add contacts associated with <span className='current-activity'><img draggable="false" className="tag" src={tagIcons[tag.index]} /> {tag.name}</span> by clicking and dragging them to your Social Desktop.If they are already on your social desktop, just click on them to associate them with this activity.
        </div>
    }
    else if (attributeQuestion) {
        content = <div className='social-desktop-hint'>
            {attributeQuestion.question}
        </div>
    }
    else if (bucket) {
        content = <div className='social-desktop-hint'>
            {bucket.question}
        </div>
    }
    else if (pageType == 'group') {
        content = <div className='social-desktop-hint'>
            Now try your best to create groups for these people. Think of ways that you best associate these people, click "Create Group" and make a name for your group. To add members to the group you have created, just drag and drop the contact to the group name. If you have contacts in more than one group, just click and drag them to every group they are a part of! When you are done making all your groups, click "Next".
        </div>
    }
    else if (pageType == 'prevideo') {
        content = <div className='social-desktop-hint'>
            Congratulations! You've completed the TellUs survey. Now you're ready to start using Coo-e: A next generation coordination collaboration tool. Click NEXT to watch a video and learn how to "TeeUp".
        </div>
    }
    else if (pageType == 'teeup') {
        content = <div className='social-desktop-hint'>
            <VideoPopUp />
            Think of a group activity (3+ people) that you need to do with some of the contacts you have placed on the social desktop below in the next week or so.  We would like to pay you to coordinate that activity using a TeeUp as we demonstrated in the video. To do that please right-click on a group or activity icon and then select create TeeUp.
        </div>
    }

    return content
}

const updateContacts = (uid) => {
    // let a = [1, 2, 3, 4];
    // cont = a;
    cont = cont.slice();
    const index = cont.findIndex((item) => {
        return item.uid == uid
    });
    if (index !== -1) {
        console.log(cont[index], "item index")
        // items.splice(index, 1);
        cont[index].draggable = true;
    }
}

const isOnDesktop = (contacts, boxItems) => {
    let value = {};
    let arr = [];
    let obj;
    contacts.map(item => {
        boxItems.map(boxItem => {
            if (item.uid === boxItem.uid) {
                obj = { 'draggable': false }
                // value = JSON.parse(JSON.stringify(item));
                // value = { ...value, a: obj }
            } else {
                obj = { 'draggable': false }
                // value = JSON.parse(JSON.stringify(item));
                // value = { ...value, a: obj }
            }

            // arr.push(value)
        })
    })
    return arr;
}
/*
    let cont = [];
    let obj = {};
    contacts.map(item => {
        boxItems.map(boxItem => {
            if (item.uid === boxItem.uid) {
                obj = JSON.parse(JSON.stringify(item));
                obj = { ...obj, draggable: false }
            } else {
                obj = JSON.parse(JSON.stringify(item));
                obj = { ...obj, draggable: true }
            }
        })
        cont.push(obj)
    })




*/

const checkingBucketBtn = (pageType, bucketCheck) => {
    if (pageType === "bucket") {
        return bucketCheck;
    } else {
        return true
    }
}

const DesktopView = ({ tags, attributes, groups, bucket, bucketGroups, bucketSelectedContacts, activeBucketUid, onBucketSelected, onRemoveBucketContact, onContactTapped, onGroupsModified, onAttribute,
    tag, attributeQuestion, onPrev, onNext, selectedTabIndex, onTabSelected, showTabs, showCreateGroup, showCreateTeeup, onCreateTeeup, onSubmitTeeUp, contacts, setTeeupContacts
    , teeupInformation, setContextSelectedTeeup, pageType, boxesRef, onContactDrop, width, height, refBoxDesktop, addContacts, modalOpened, onCloseModal, onOpenModal,
    finishToAddContact, teeup, teeupContacts, onUpdateTeeUp, boxItems, onUpdateBoxItems, resizeDesktop, uid, deletedUid, waitingPage, contactData, stepGroup, updateContactList, current, bucketNextBtn, deleteGroup, }) => {


    let tabs = [
        <BoxContacts
            targetKey="box"
            // contact={isOnDesktop(contacts, boxItems)}
            contacts={contacts}
            boxItems={boxItems}
            addContacts={addContacts}
            uid={uid}
            deletedUid={deletedUid}
            waitingPage={waitingPage} // TEMPORARY FOR TEST

        />,
        <BoxActivities
            waitingPage={waitingPage} // TEMPORARY FOR TEST
            targetKey="box"
            tags={tags}
            attributes={attributes}
            groups={groups}
            onCreateTeeup={onCreateTeeup}
        />
    ]

    let tabButtons = showTabs ? [
        <div draggable="false" key="contacts" className={'tab ' + (selectedTabIndex == 0 ? ' selected' : '')} onClick={() => onTabSelected(0)} src={tabContacts} >
            <img draggable="false" width="20" height="20" style={{ marginLeft: 10, marginTop: 5 }} src={tabContacts} />
        </div>,
        <div draggable="false" key="activities" className={'tab' + (selectedTabIndex == 1 ? ' selected' : '')} onClick={() => onTabSelected(1)} src={tabActivities} >
            <img draggable="false" width="20" height="20" style={{ marginLeft: 10, marginTop: 5 }} src={tabActivities} />
        </div>
    ] : null

    return <div className="social-desktop-container">
        <HintView
            tag={tag}
            attributeQuestion={attributeQuestion}
            bucket={bucket}
            pageType={pageType}
        />
        <div className="boxes" ref={boxesRef} style={{ width, height }}>
            <div className="tabs">
                {tabButtons}
            </div>
            {tabs[selectedTabIndex]}
            <BoxDesktop
                pageType={pageType}
                boxItems={boxItems}
                attributes={attributes}
                refBoxDesktop={refBoxDesktop}
                onCreateTeeup={onCreateTeeup}
                onContactTapped={onContactTapped}
                showCreateGroup={showCreateGroup}
                targetKey="box"
                tag={tag}
                tags={tags}
                attributeQuestion={attributeQuestion}
                onGroupsModified={onGroupsModified}
                onAttribute={onAttribute}
                bucketSelectedContacts={bucketSelectedContacts}
                groups={groups}
                bucket={bucket}
                deletedUid={deletedUid}
                updateContacts={updateContacts}
                setTeeupContacts={setTeeupContacts}
                setContextSelectedTeeup={setContextSelectedTeeup}
                onContactDrop={onContactDrop}
                items={boxItems}
                onUpdateItems={onUpdateBoxItems}
                resizeDesktop={resizeDesktop}
                contactData={contactData}
                finishToAddContact={finishToAddContact}
                stepGroup={stepGroup}
                contacts={contacts}

                updateContactList={updateContactList}

                // updateContactList={(uid) => updateContactList(uid)}
                deleteGroup={deleteGroup}

                current={current}
            />

        </div>

        {bucket && <div className="buckets" >
            {bucket.groups.map(group => {
                let contacts = bucketGroups[group.id] ? bucketGroups[group.id] : []
                return <div className="bucket_container" key={group.id}>
                    <div className="title">{group.name}</div>
                    <div className={"bucket " + (activeBucketUid == group.id ? 'bucket_selected' : '')} onClick={() => onBucketSelected(group.id)}>
                        {contacts.map((contact, index) => <BucketItem key={index} {...contact} onRemove={() => onRemoveBucketContact(bucket.index, group.id, contact.uid)} />)}
                    </div>
                </div>
            })}
        </div>}

        <div className="bottomContainer">
            <div className={"rounded-button bcolor-pink" + (onPrev ? '' : ' bcolor-disabled')} onClick={onPrev}>
                <span className="rounded-button-text">Previous</span>
            </div>
            {
                checkingBucketBtn(pageType, bucketNextBtn) ?
                    <div className={"rounded-button bcolor-pink" + (onNext ? '' : ' bcolor-disabled')} onClick={onNext}>
                        <span className="rounded-button-text">Next</span>
                    </div>
                    :
                    <div className={"rounded-button bcolor-color-disabled" + (onNext ? '' : ' bcolor-disabled')} style={{ backgroundColor: '#e1e1e1' }} onClick={onNext}>
                        <span className="rounded-button-text">Next</span>
                    </div>
            }

        </div>

        {!!teeup &&
        <CreateTeeupModal
            teeup={teeup}
            teeupContacts={teeupContacts}
            onUpdateTeeUp={onUpdateTeeUp}
            onSubmitTeeUp={onSubmitTeeUp}
        />
        }

        {showCreateTeeup &&
            <CreateTeeup
                teeupInformation={teeupInformation}
            />}

        <ContactModal
            modalOpened={modalOpened}
            onCloseModal={onCloseModal}
            onOpenModal={onOpenModal}
            finishToAddContact={finishToAddContact}
        />

    </div>
}

export default DesktopView