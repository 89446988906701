import React from 'react'

const validateInput = (value, initialValidation) => {
    let isInvalid = true
    if ((value && (typeof value === 'string') && value.trim() !== '') || initialValidation) {
        isInvalid = false
    }
    return isInvalid
}

const coordinationFrequency = {
    do: [

        `Once a week`,
        `A few times a Week`,
        `Biweekly (every other week)`,
        `A few times a Month`,
        `A few times a Month`,
        `Once a Month`,
        `Bi Monthly (every other month)`,
        `Once every few months (every 3-4 months)`,
        `A few times a Year`,
        'Once a Year',
        // 'A few times a semester',
        // 'monthly',
        // 'A few times a month',
        // 'Weekly',
        // 'A few times a week',
    ],
    like: [
        `Once a Week`,
        `A few times a Week`,
        `Biweekly (every other week)`,
        `A few times a Month`,
        `Once a Month`,
        `Bi Monthly`,
        `Once every few months (every 3-4 months)`,
        `A few times a Year`,
        `Once a Year`,

    ],
    help: [
        `Yes`,
        `No`
    ]
};


const yesNo = [
    'Yes',
    'No',
]

const interestGroups = 'interestGroups'

const QuizView = ({ questions, askingQuestions, initialValidation, frequencyAnswers, onBack, onNext, activityType, flag, name }) => {
    const createTitle = (question) => {
        if (name === "interestGroups") {
            return (
                <p className='quiz-question-row'>I {question.status} {question.action} {question.activity} at {question.location}</p>
            )
        }
        return (
            <p className='quiz-question-row'>I {question.action} {question.activity} with {question.with} at {question.location}</p>
        )


    }
    return (
        <div>
            <table className='activity-table'>
                <tbody>
                    {questions.map((question, index) => (
                        <tr key={index} className='activity-table'>
                            <td>
                                {createTitle(question)}
                            </td>
                            <td>
                                <select
                                    onChange={(event) => frequencyAnswers('do', event.target.value, index, flag)}
                                    className={"input-form select-form quiz-input " + (validateInput(question.frequencyAnswers.do, initialValidation) && ' input-form-invalid')}>
                                    <option disabled selected hidden>Select...</option>
                                    {coordinationFrequency.do.map(option => (
                                        <option selected={option === question.frequencyAnswers.do} key={option}
                                            value={option}>{option}</option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <select
                                    onChange={(event) => frequencyAnswers('like', event.target.value, index, flag)}
                                    className={"input-form select-form quiz-input " + (validateInput(question.frequencyAnswers.like, initialValidation) && ' input-form-invalid')}>
                                    <option value={question.frequencyAnswers.like} disabled selected hidden>Select...
                                </option>
                                    {coordinationFrequency.like.map(option => (
                                        <option selected={option === question.frequencyAnswers.like} key={option}
                                            value={option}>{option}</option>
                                    ))}
                                </select>
                            </td>

                            <td>
                                <select
                                    onChange={(event) => frequencyAnswers('help', event.target.value, index, flag)}
                                    className={"input-form select-form quiz-input " + (validateInput(question.frequencyAnswers.help, initialValidation) && ' input-form-invalid')}>
                                    <option value={question.frequencyAnswers.help} disabled selected hidden>Select...
                                </option>
                                    {coordinationFrequency.help.map(option => (
                                        <option selected={option === question.frequencyAnswers.help} key={option}
                                            value={option}>{option}</option>
                                    ))}
                                </select>
                            </td>
                            {/* {name === "interestGroups" &&
                                <td>
                                    <select
                                        onChange={(event) => frequencyAnswers('open', event.target.value, index, flag)}
                                        className={"input-form select-form quiz-input " + (validateInput(question.frequencyAnswers.open, initialValidation) && ' input-form-invalid')}>
                                        <option value={question.frequencyAnswers.open} disabled selected hidden>Select...
                                </option>
                                        {yesNo.map(option => (
                                            <option selected={option === question.frequencyAnswers.open} key={option}
                                                value={option}>{option}</option>
                                        ))}
                                    </select>
                                </td>
                            } */}
                            {/* {name === "interestGroups" &&
                                <td>
                                    <label className="checkbox-container margin-right05">
                                        <input type="checkbox"
                                            checked={
                                                // this work but maybe need better solution
                                                question.frequencyAnswers.checkOff === "" || question.frequencyAnswers.checkOff === "false" ? false : true
                                            }
                                            onChange={(event) => {
                                                frequencyAnswers('checkOff', event.target.checked.toString(), index, flag)
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </td>
                            } */}

                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    )
}

export default QuizView