
import actionTypes from '../actions/actionTypes'

import { Endpoints } from '../configs/endpoints'
import backend from 'apis/backend'

import { loginProviders } from 'configs/enums'

import Auth0 from 'auth0-js'
// import Auth0 from 'react-native-auth0'
// import jwtDecode from 'jwt-decode'

let globalDispatch

// social media login
let credentials

const auth0 = new Auth0.WebAuth({
    domain: 'cooe1.auth0.com',
    clientID: 'Cj40hzsYe7xelZ21SWU3RgYCcnUSyGIl',
    redirectUri: Endpoints.auth0Callback,
    responseType: 'token id_token',
    scope: 'openid profile email'
})

const tryAuthFB = () => {
    tryAuth(loginProviders.fb, true)
}

const tryAuthGoogle = () => {
    tryAuth(loginProviders.google, true)
}

const tryAuthEmail = (email) => {
    tryAuth(loginProviders.email, true, email)
}

const tryLoginSocial = (connection) => {
    return auth0.authorize({
        connection,
        prompt: 'login',
    })
}

const tryAuth = (connection, newUser, email) => {
    return new Promise((resolve, reject) => {
        if (connection === loginProviders.email) {
            // If email register, simply verify that email can be registererd
            if (email) {
                return verifyUser(email, connection, newUser)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        console.log("do something")
                        reject(error)
                    })
            }
        }
        else {
            // If social register, authorize user, get social profile, prefill userInfo data
            auth0.client.userInfo(credentials.accessToken, function (error, userInfo) {

                if (error) {
                    console.log('error ', error)
                    reject(error)
                }

                return verifyUser(userInfo.email, connection, newUser)
                    .then(() => {
                        let avatar = userInfo.picture
                        if (connection == loginProviders.fb) {
                            let userId = userInfo.sub.split('|')[1]
                            console.log(userId, "userId")
                            let picture = `https://graph.facebook.com/${userId}/picture?width=200&height=200`
                        }

                        let socialUserInfo = {
                            email: userInfo.email,
                            firstName: userInfo.givenName,
                            lastName: userInfo.familyName,
                            nickname: userInfo.nickname,
                            sub: userInfo.sub,
                            // picture: userInfo.picture, // for facebook, this picture is too small
                            avatar,
                            loginType: connection,
                        }

                        globalDispatch({ type: actionTypes.AUTH_END, payload: { credentials, userInfo: socialUserInfo } })
                        let data = { credentials, socialUserInfo }
                        resolve(data)
                        return data
                    })
            })
        }
    })
        .catch(error => {
            console.log("error tryAuth")
            console.log(error)

            if (error.error && error.error.includes('user_cancelled')) {

            }
            else {

                if (error.error_description) {
                    error = error.error_description
                }

                alert(error)
            }

            globalDispatch({ type: actionTypes.AUTH_END, payload: { error } })

            throw null
        })

}

const verifyUser = (email, connection, newUser) => {
    return backend.verify(email)
        .then(({ exists, method }) => {
            if (!exists && !newUser) {
                let error = `Email ${email} not found. Register first please.`
                throw error
            }

            if (exists && (method != connection || newUser)) {
                let error = `${email} already registered via ${method}.`
                throw error
            }

        })
}

const tryLogin = (connection, loginCredentials) => {

    let userId = null
    let userToken = null

    return new Promise((resolve, reject) => {
        if (connection === loginProviders.email) {
            // Email:
            //  verify
            //  login
            //  setToken
            //  getProfile
            resolve(loginCredentials)
        }
        else {
            // Social:
            //  auth
            //  getProfile
            //  verify
            //  login
            //  setToken
            //  getProfile
            tryAuth(connection)
                .then((data) => {
                    const { credentials, socialUserInfo } = data ? data : {}
                    resolve({ email: socialUserInfo.email, idToken: credentials.idToken })
                })
                .catch(error => reject(error))
        }
    })
        .then(credentials => {
            const { email, password, idToken } = credentials
            return verifyUser(email, connection)
                .then(() => {
                    return backend.login(email, password, idToken, connection)
                })
                .then(userInfo => {
                    let token = connection === loginProviders.email ? userInfo.auth_token : idToken
                    userToken = connection === loginProviders.email ? userInfo.auth_token : null
                    // idToken will be added with every request
                    backend.setToken(token)
                    // Only returns id
                    userId = userInfo.id
                    console.log(userId, 'userId', userToken)
                    return backend.fetchUserInfo(userId)
                })
                .then(userInfo => {
                    userInfo.id = userId
                    userInfo.verified = true

                    let payload = { userInfo }
                    if (userToken) {
                        payload.credentials = { idToken: userToken }
                    }

                    globalDispatch({ type: actionTypes.LOGIN_END, payload })
                    globalDispatch({ type: actionTypes.FETCH_TEELUS_INFORMATION, payload: { userId: userInfo.id } })

                    globalDispatch({ type: actionTypes.GET_DESKTOP_STATE, payload: { userId: userInfo.id } })
                    return userInfo
                })
        })
        .catch(error => {
            if (error) {
                console.log("tryLogin error")
                console.log(error)
                alert(error)
                globalDispatch({ type: actionTypes.AUTH_END, payload: { error } })
            }
        })

}

const logout = (shouldClearSession) => {

    if (shouldClearSession) {
        auth0.webAuth.clearSession()
    }
}

const register = (userInfo) => {
    userInfo = { ...userInfo }
    // userInfo.timezone = getTimezone()
    backend.register(userInfo)
        .then(response => {
            userInfo = { ...userInfo }
            userInfo.avatar = response.avatar
            userInfo.id = response.id
            userInfo.verified = true

            let payload = { userInfo }
            if (response.auth_token) {
                backend.setToken(response.auth_token)
                payload.credentials = { idToken: response.auth_token }
            }

            globalDispatch({
                type: actionTypes.REGISTER_END,
                payload,
            })
        })
        .catch(error => {
            console.log("register error")
            console.log(error)
            // routeBackToEmail()
            console.log("error while registering")
            alert('Error while registering')
            // TODO: handle errors
        })
}

export default function middleware({ dispatch, getState }) {

    globalDispatch = dispatch

    return next => action => {

        switch (action.type) {

            case 'persist/REHYDRATE': {

                break
            }

            case actionTypes.LOGOUT: {

                // let credentials = getState().user.credentials
                // let accessToken
                // if (credentials && credentials.accessToken) {
                //     accessToken = credentials.accessToken
                // }
                // logout(accessToken)

                break
            }

            case actionTypes.AUTH_FB: {
                tryAuthFB()
                break
            }

            case actionTypes.AUTH_GOOGLE: {
                tryAuthGoogle()
                break
            }

            case actionTypes.AUTH_EMAIL: {
                const { email } = getState().user.userInfo
                tryAuthEmail(email)
                break
            }

            case actionTypes.LOGIN_EMAIL: {
                tryLogin(loginProviders.email, action.payload)
                break
            }

            case actionTypes.LOGIN_GOOGLE: {
                // tryLogin(loginProviders.google)
                tryLoginSocial(loginProviders.google)
                break
            }

            case actionTypes.LOGIN_FB: {
                // tryLogin(loginProviders.fb)
                tryLoginSocial(loginProviders.fb)
                break
            }

            case actionTypes.LOGIN_SOCIAL_CREDENTIALS: {
                credentials = action.payload.credentials
                tryLogin(getState().user.connection)
                break
            }

            case actionTypes.REGISTER: {
                const { userInfo } = getState().user
                register(userInfo)
                break
            }

        }


        // Call the next dispatch method in the middleware chain.
        let returnValue = next(action)

        // console.log('state after dispatch', getState())

        // This will likely be the action itself, unless
        // a middleware further in chain changed it.
        return returnValue
    }
}