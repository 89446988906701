import { Endpoints } from '../configs/endpoints'

let token

const getHeaders = () => {
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }

    if (token) {
        headers.Authorization = 'Bearer ' + token
    }

    return headers
}

const setToken = (aToken) => {
    console.log("Setting token")
    token = aToken
}

const handledFetch = (url, method, body) => {
    // console.log(token, "token")
    let bodyStringified = JSON.stringify(body)
    console.log(body, "body");
    console.log(bodyStringified, "stringify")
    return fetch(url, {
        method,
        headers: getHeaders(),
        body: bodyStringified
    })
        .then(response => {
            console.log(response, "response")
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            }
            else if (response.status === 422) {
                console.log("error 422")
                console.log(response)
                return Promise.reject(response.json())
            }
            else if (response.status === 401) {
                console.log("error 401")
                console.log(response)
                return Promise.reject(response.json())
            }
            else if (response.state === 409) {
                console.log("ERROR 409")
                return Promise.reject(response.json())
            }
            else {
                let error = response.error
                console.log("error ELSE")
                console.log(response)
                return Promise.reject(error)
            }
        })
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            }
            else if (response.status >= 400) {
                return Promise.reject(response)
            }
            else {
                return response
            }
        })
        .catch(error => {
            console.log("Backend error")
            console.log(error)
            return Promise.reject(error)
        })
}
// -----------------------------------------------Login------------------------------------------------------------------------------
const sendContactInfo = (emailUni, phone, countryCode) => {
    let url = Endpoints.sendContactInfo

    let body = {
        email1: emailUni,
        phoneNumber: phone,
        countryCode: countryCode,
    }

    console.log(body, "body")
    return handledFetch(url, 'POST', body)
}

const editContact = (body, contactId) => {
    let url = Endpoints.editContact(contactId)
    return handledFetch(url, 'PUT', body)
}


const verifyContactInfo = (data) => {
    let url = Endpoints.verifyContactInfo

    let body = data

    return handledFetch(url, 'POST', body)
}

// -----------------------------------------------Data------------------------------------------------------------------------------
const fetchLanguages = () => {
    let url = Endpoints.languages

    return handledFetch(url, 'GET')
}

const fetchMajors = () => {
    let url = Endpoints.majors

    return handledFetch(url, 'GET')
}

// -----------------------------------------------Login------------------------------------------------------------------------------
const login = (email, password, token, loginType) => {
    let url = Endpoints.login

    let body = {
        email,
        type: loginType,
    }

    if (password) {
        body.password = password
    }

    if (token) {
        body.auth_token = token
    }

    return handledFetch(url, 'POST', body)
}

const fetchUserInfo = (id, isForSync) => {
    let url = isForSync ? Endpoints.userInfo(id) + "/draft" : Endpoints.userInfo(id);

    return handledFetch(url, 'GET')
}

const verify = (email) => {
    let url = Endpoints.verify

    let body = {
        email,
    }

    return handledFetch(url, 'POST', body)
}

const register = (userInfo, token) => {
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         let updated = { ...userInfo }
    //         updated.verified = true
    //         resolve(updated)
    //     }, 1500)
    // })

    let url = Endpoints.register

    const { email, firstName, lastName, avatar, picture, sub, nickname, loginType, password, timezone } = userInfo

    let body = {
        "name": firstName + ' ' + lastName,
        "lastname": lastName,
        "firstname": firstName,
        "username": nickname,
        "email": email,
        "password": password,
        "locale": "en",
        "sub": sub,
        "avatar": picture ? picture : avatar,
        "avatartype": picture ? "image/base64" : "image/url",
        "type": password ? 'email' : loginType,
        "timezone": timezone,
    }

    return handledFetch(url, 'POST', body)
}

const sendActivityGroups = (id, groups) => {
    let url = Endpoints.activityGroups(id)

    let body = groups

    return handledFetch(url, 'POST', body)
}

const sendContactGroups = (id, groups) => {
    let url = Endpoints.contactGroups(id)

    let body = {
        userId: id,
        groups,
    }

    return handledFetch(url, 'POST', body)
}

const sendAttributeGroups = (id, attributes) => {
    let url = Endpoints.attributeGroups(id)

    let body = {
        userId: id,
        attributes,
    }

    return handledFetch(url, 'POST', body)
}

const sendBucketGroups = (id, buckets) => {
    let url = Endpoints.bucketGroups(id)

    let body = {
        userId: id,
        buckets,
    }

    return handledFetch(url, 'POST', body)
}

const sendQuestions = (questions, userId) => {
    let url = Endpoints.demographics(userId)
    return handledFetch(url, 'POST', questions)
}

const getQuestions = (userId) => {
    let url = Endpoints.demographics(userId)
    return handledFetch(url, 'GET')
}

const sendActivitiesQuestions = (questions) => {
    let url = Endpoints.activities(questions.userId)
    return handledFetch(url, 'POST', questions)
}

const getActivitiesQuestions = (userId) => {
    let url = Endpoints.activities(userId)
    return handledFetch(url, 'GET')
}

const fetchContacts = (id) => {
    let url = Endpoints.fetchContacts(id)
    return handledFetch(url, 'GET')
}

const syncContacts = (body, userId) => {
    let url = Endpoints.syncContacts(userId)
    return handledFetch(url, 'POST', body)
}

const getRecommendedTeeUp = (userId, contactIds) => {
    let url = Endpoints.recommendTeeUp(userId)

    let body = {
        contactIds
    }

    return handledFetch(url, 'POST', body)
}

const getTeeUp = (userId) => {
    let url = Endpoints.teeups(userId)
    return handledFetch(url, 'GET')
}

const postDraftedTeeUp = (userId, name, location, inviteMessage, contactIds) => {
    let url = Endpoints.recommendTeeUp(userId)

    let body = {
        activity: {
            name,
            location,
            contactIds,
            inviteMessage,
        },
    }

    return handledFetch(url, 'PUT', body)
}


const postDesktopState = (state, userId) => {
    let url = Endpoints.desktopState(userId)
    return handledFetch(url, 'POST', { state })
}

const getDesktopState = (userId) => {
    let url = Endpoints.desktopState(userId)
    return handledFetch(url, 'GET')
}

const editContactInfo = (body) => {
    let url = Endpoints.editContactInfo;
    return handledFetch(url, 'PUT', body);
}

// -----------------------------------------------Payment------------------------------------------------------------------------------
const postPayment = (body, userId) => {
    let url = Endpoints.postPayment(userId);
    return handledFetch(url, 'POST', body);
}

// -----------------------------------------------interests------------------------------------------------------------------------------

const searchInterests = (value) => {
    let url = Endpoints.searchInterests(value);
    return handledFetch(url, 'GET');
}

const listInterests = (userId) => {
    let url = Endpoints.interestsList(userId);
    return handledFetch(url, 'GET');
}

const followInterests = (userId, body) => {
    let url = Endpoints.followInterests(userId);
    return handledFetch(url, 'POST', body);
}

const createInterests = (userId, data) => {
    let url = Endpoints.createInterests;

    let body = {
        "userId": userId,
        "value": data
    }
    return handledFetch(url, 'POST', body)
}

const unFollowInterests = (userId, interestsId) => {
    let url = Endpoints.unFollowInterests(userId, interestsId);

    return handledFetch(url, 'DELETE')
}

export default {
    setToken,
    register,
    sendContactInfo,
    verifyContactInfo,
    login,
    verify,
    editContactInfo,

    fetchUserInfo,
    sendQuestions,
    getQuestions,
    sendActivitiesQuestions,
    getActivitiesQuestions,
    fetchContacts,
    editContact,

    sendActivityGroups,
    sendContactGroups,
    sendAttributeGroups,
    sendBucketGroups,

    fetchLanguages,
    fetchMajors,
    syncContacts,

    getRecommendedTeeUp,
    getTeeUp,
    postDraftedTeeUp,
    postPayment,

    postDesktopState,
    getDesktopState,

    listInterests,
    searchInterests,
    followInterests,
    createInterests,
    unFollowInterests
}

export {
    setToken,
    register,


}
