import React, { Component } from 'react'
import { connect } from "react-redux"
import ActivitiesView from './ActivitiesView'
import { bindActionCreators } from 'redux'
import activitiesActions from 'actions/activitiesActions'
import stepActions from 'actions/stepActions'
import desktopActions from '../../actions/desktopActions';
import { stepIds } from 'configs/enums'
import _ from 'lodash'
import hmtlParser from 'html-react-parser'
import interestsActions from '../../actions/interestsActions'

const examples = {
    header: `<span class="example-paragraph">We are interested in the social group activites that you do with friends on, or near coxeampus. Please fill in the boxes provided with the appropriate information.`,

    // GOOD Examples
    //     attend  with my IS 375 Group at Campus Center 4th floor.
    //    I play Basketball with The Theta Chi Fraternity at The WEC.


    // What not to do:
    //         I write a story for the vector in my dorm room(not a social group activity)
    //    I attend group meetings with my CS100 group(not a physicial location)</span> `,
    goodExamples: [
        {
            text: `<span class="good-examples-paragraph"> I <span class="color-orange"> attend</span> <span class="color-pink-2">Class Project Meetings
            </span> with my <span class="color-teal"> IS 375 Group</span><span class="color-blue">at Campus Center 4th floor.</span></span > `
        },
        {
            text: `<span class="good-examples-paragraph"> I <span class="color-orange"> play</span> <span class="color-pink-2">Basketball
            </span> with <span class="color-teal">The Theta Chi Fraternity</span> <span class="color-blue">at The WEC.</span></span > `
        },
    ]
}


const emptyQuestion = (activityType) => {

    let placeholderQuestion = {
        action: '',
        activity: '',
        location: '',
        member: 'no',
        frequencyAnswers: {
            do: '',
            like: '',
            checkOff: 'false'
        }
    }

    switch (activityType) {
        case "campus": {
            placeholderQuestion.frequencyAnswers.help = ''
            placeholderQuestion.with = ''
            break
        }
        case "classRelated": {
            placeholderQuestion.frequencyAnswers.help = ''
            placeholderQuestion.with = ''
            break
        }
        case "classRelated": {
            placeholderQuestion.frequencyAnswers.help = ''
            placeholderQuestion.with = ''
            break
        }
        case "interestGroups": {
            placeholderQuestion.frequencyAnswers.open = 'j'
            // placeholderQuestion.status = 'g'
            break
        }
        default: console.log("default")
    }

    return placeholderQuestion
}

class ActivitiesContainer extends Component {

    initialQuestions = () => {
        let questions = [
            {
                action: '',
                activity: '',
                with: '',
                location: '',
                member: 'no',
                frequencyAnswers: {
                    do: '',
                    like: '',
                    checkOff: 'false',
                    help: '',
                }
            },
            {
                action: '',
                activity: '',
                with: '',
                location: '',
                member: 'no',
                frequencyAnswers: {
                    do: '',
                    like: '',
                    checkOff: 'false',
                    help: '',
                }
            },
            {
                action: '',
                activity: '',
                with: '',
                location: '',
                member: 'no',
                status: '',
                frequencyAnswers: {
                    do: '',
                    like: '',
                    checkOff: 'false',
                    open: ''
                }
            },
        ]

        return questions
    }



    updateQuestionProperty = async (property, value, index, name) => {
        let activ = []
        this.state.activity.map(item => {
            if (item.name === name) {
                item.questions.map(question => {
                    activ.push(question)
                })
            }
        });
        let question;
        if (index > activ[0].length) {
        } else {
            question = activ[index];
        }

        question = {
            ...question,
            [property]: value,
        }

        let updatedActivity = this.state.activity.map(item => {
            if (item.name === name) {
                item.questions[index] = question
            }

            return item
        })


        this.setState({
            qu: question,
            activity: updatedActivity
        })

        await this.checking(updatedActivity)
    }

    checkValidate = () => {
        let validated = true;
        let count = 0;
        this.state.activity.map(item => {
            item.questions.map(question => {
                for (let key in question) {
                    if (question[key] === "") {
                        validated = false
                        break
                    } else {
                        validated = true;
                    }
                }
            })
            count += 1;
        })

        return validated;
    }

    addActivity = (name) => {
        let te = [];
        let ac = this.state.activity;


        ac.map(item => {
            te = item;
            if (item.name === name) {
                te.questions.push(emptyQuestion(name))
            }

        })

        this.setState({ activities: te, validated: false })
    }

    questionsValidation = (key, value) => {
        const { activityType } = this.state
        if (activityType !== 'interestGroups' && key === 'status') {
            return false
        }
        if (activityType === 'interestGroups' && key === 'with') {
            return false
        }
        return value === ''
    }

    onNext = () => {
        this.setState({
            initialValidation: false
        })
        let validated = true
        let a = [];
        let questions = [];
        let questionsInterests = [];
        this.state.activity.map(item => {
            item.questions.map(question => {
                for (let key in question) {
                    if ((typeof question[key] === 'string') && this.questionsValidation(key, question[key].trim())) {
                        validated = false
                        a = [...a, question]
                    } else {
                        questions = [...questions, question]
                        break
                    }
                }
            })
        })

        questions.map(qe => {
            questionsInterests = [...questionsInterests, qe.activity]
        })


        if (validated) {

            this.props.interestsActions.saveUserInterests(questionsInterests)
            // this.props.interestsActions.createInterests("interest")

            this.state.activity.map(item => {
                if (item.questions.length !== 0) {
                    this.props.activitiesActions.setActivityQuestions(item.questions, item.name)
                }
            })
            this.props.history.push('quiz')
        }
    }

    onBack = () => {
        this.props.history.replace("/survey/activities/selector")
    }

    constructor(props) {
        super(props)

        let activityType = props.location.pathname.split("/").pop()
        let undeletableAmount = activityType == 'interestGroups' ? 3 : 1

        this.state = {
            activity: [],
            undeletableAmount,
            questions: [],
            initialValidation: true,
            activityType,
            qu: []
        }

        props.stepActions.setActiveStep(stepIds.activities)
    }

    componentDidMount() {
        this.props.desktopActions.formatContact();
        let { activitySelectors, activities } = this.props;
        let activityCopy = [];
        for (let activity in activitySelectors) {

            if (activitySelectors[activity] === "true") {
                let act = JSON.parse(JSON.stringify(activities[activity]));
                act = { ...act, name: activity }
                if (act.questions.length === 0) {
                    act.questions.push(emptyQuestion(activity))
                } else {
                }
                activityCopy = [...activityCopy, act]
            }
        }

        this.setState({
            activity: activityCopy,
        })

        this.checking(activityCopy)
    }


    checking = (a) => {
        let validated = true;
        a.map(item => {
            item.questions.map(question => {
                for (let key in question) {
                    if ((typeof question[key] === 'string') && this.questionsValidation(key, question[key].trim())) {
                        validated = false
                    }
                }
            })
        })
        this.setState({ validated: validated })
    }

    deleteActivity = (indexToDelete, name) => {
        let stateQuestions;
        let key;

        for (let activ in this.state.activity) {
            if (name === this.state.activity[activ].name) {
                stateQuestions = this.state.activity[activ].questions;
                key = activ
            }
        }

        _.remove(stateQuestions, (item, index) => {
            return index === indexToDelete
        })

        let proba = this.state.activity;

        proba[key].questions = stateQuestions

        this.setState({
            questions: proba
        })
    }

    renderExamples = () => {
        return (
            <div>
                <div className="activity-table-example" style={{ width: '750px', margin: '0 auto', borderRadius: '16px', marginBottom: 10 }}>
                    {hmtlParser(examples.header)}
                    <h1 style={{ marginBottom: -5, marginTop: 10, fontSize: '16px', fontFamily: 'Helvetica' }}>GOOD Examples</h1>
                    {
                        examples.goodExamples.map(item => (
                            <div style={{ marginLeft: 10 }}>
                                {hmtlParser(item.text)}
                            </div>
                        ))
                    }
                    <h1 style={{ marginBottom: -5, marginTop: 10, fontSize: '16px', fontFamily: 'Helvetica' }}>What not to do:</h1>
                    <div style={{ marginLeft: 10 }}>
                        <span><b>I write a story for the vector in my dorm room (not a social group activity)</b></span><br />
                        <span><b>I attend group meetings with my CS100 group (not a physicial location)</b></span>
                    </div>
                </div>
                <div className="activity-table-example" style={{ width: '750px', margin: '0 auto', borderRadius: '16px' }}>
                    <table className='activity-table'>
                        <thead >
                            <tr >
                                <th >Action</th>
                                <th>Activity</th>
                                <th>with</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tr className=''>
                            <td style={{ paddingRight: '16px' }}>
                                <select className="input-form select-form action-input orange">
                                    <option value="" disabled selected hidden>attend</option>
                                </select>
                            </td>
                            <td className='activity-table-padding'>
                                <input className=" input-form activity-input red"
                                    value="executive board editorial meetings" type="text" disabled={true} />
                            </td>
                            <td className='activity-table-padding '>
                                <input className=" input-form with-input blue"
                                    value="The Vector" type="text" disabled={true} />
                            </td>
                            <td className='activity-table-padding'>
                                <input className=" input-form with-input purple"
                                    value="The Campus Center 4th Floor" type="text" disabled={true} />
                            </td>
                        </tr>
                    </table>
                </div>
            </div >
        )
    }

    render() {
        const { initialValidation, activityType, undeletableAmount, activity } = this.state
        return (
            <div>
                {this.renderExamples()}
                {
                    activity ? activity.map(item => (
                        <ActivitiesView
                            name={item.name}
                            paragraph1={item.paragraph1}
                            paragraph2={item.paragraph2}
                            examples={item.examples}
                            badExamples={item.badExamples}
                            questions={item.questions}
                            updateQuestionProperty={this.updateQuestionProperty}
                            addActivity={name => this.addActivity(name)}
                            onNext={this.onNext}
                            onBack={this.onBack}
                            initialValidation={initialValidation}
                            activityType={activityType}
                            deleteActivity={this.deleteActivity}
                            undeletableAmount={undeletableAmount}
                        />
                    )) : null
                }
                <div style={{ paddingTop: '137px' }} />
                <div className='activity-button-container'>
                    <div className={"rounded-button bcolor-pink"} onClick={this.onBack}>
                        <span className="rounded-button-text">Back</span>
                    </div>
                    {
                        !this.state.validated ?
                            <div className={"rounded-button bcolor-color-disabled"} style={{ backgroundColor: '#e1e1e1' }} onClick={this.onNext}>
                                <span className="rounded-button-text">Next</span>
                            </div>
                            :
                            <div className={"rounded-button bcolor-pink"} onClick={this.onNext}>
                                <span className="rounded-button-text">Next</span>
                            </div>
                    }

                </div>
            </div>

        )
    }
}


const mapStateToProps = (state) => ({
    activities: state.activities,
    activitySelectors: state.data.activitySelectors,

})

const mapDispatchToProps = (dispatch) => ({
    activitiesActions: bindActionCreators(activitiesActions, dispatch),
    stepActions: bindActionCreators(stepActions, dispatch),
    desktopActions: bindActionCreators(desktopActions, dispatch),
    interestsActions: bindActionCreators(interestsActions, dispatch),
    saveUserInterests: bindActionCreators(interestsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesContainer)