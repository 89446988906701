import React from 'react'
import ReactDOM from 'react-dom'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
import Draggable from 'react-draggable'
import { getTagColor } from 'utils/stylesUtils'

import './boxItem.css'

import grabber from 'assets/svg/grabber.svg'

import tagIcons from './tagIcons'

/*
    BoxItem - a thing that appears in a box, after you drag something into the box
*/

export default class BoxItem extends React.Component {
    // the things that appear in the boxes
    constructor(props) {
        super(props)

        this.state = {
            initial: true,
            isDragged: false,
        }
    }

    onDragStop = (e) => {

        if (this.props.onDragStop) {

            var rect = ReactDOM.findDOMNode(this.viewRef)
                .getBoundingClientRect()

            // this.props.onDragStop(e, this.props.item)
            this.props.onDragStop(rect, this.props.item)
        }
    }

    onDragStart = (e) => {
        this.setState({ initial: false, isDragged: false })
    }

    onDrag = (e) => {
        if (!this.state.isDragged) {
            this.setState({ isDragged: true })
        }

        if (this.props.onDrag) {
            this.props.onDrag(this.props)
        }
    }

    onTap = () => {
        if (!this.state.isDragged && this.props.onItemTapped) {
            this.props.onItemTapped(this.props)
        }
    }

    handleDrop = (e) => {
        e.stopPropagation();
        this.props.swap(e.dragData.index, this.props.index, e.dragData);
        e.containerElem.style.visibility = "hidden";
    }

    deleteMe = () => {
        this.props.kill(this.props.uid);
    }

    componentDidUpdate() {
        // console.log('UPDATE')
    }


    render() {
        /*
          Notice how these are wrapped in a DragDropContainer (so you can drag them) AND
          in a DropTarget (enabling you to rearrange items in the box by dragging them on
          top of each other)
        */

        let { name, index, uid, tags, allTags, attributes, callback, onContextMenu, activeTagId, activeAttributeIndex, draggable, position, alreadySelected, item } = this.props
        let { initial } = this.state

        const hasTags = tags && tags.length > 0
        let style = null

        let renderedTags = hasTags ? tags.map((localTag, index) => {
            let tag = allTags[localTag.id]
            if (!tag) {
                // Somehow this tag doesn't exist anymore
                return null
            }

            if (tag.id == activeTagId) {
                style = { borderColor: getTagColor(tag.index), borderWidth: 2 }
            }
            return <img draggable="false" key={index} className="tag" src={tagIcons[tag.index]} />
        }) : null

        const hasAttributes = attributes && attributes.length > 0
        if (hasAttributes) {
            attributes.forEach(element => {
                if (element.index == activeAttributeIndex) {
                    style = { borderColor: 'red', borderWidth: 2 }
                }
            })
        }

        let design = <div className={"outer box_item_component" + (draggable ? (' ' + uid) : '')} onContextMenu={(event) => {
            onContextMenu(event)
            callback(event, this)
        }} >
            {/* <div className={"item" + (hasTags ? ' tagged': '')}> */}
            <div className={"item" + (alreadySelected ? (' item-selected') : '')} style={style} onClick={this.onTap}>
                <div className="image">

                </div>
                <div className="content">
                    <div className="name">{name}</div>
                    <div className="tags">
                        {renderedTags}
                    </div>
                </div>

                <div className="spacer" />

                <div className="grabber">
                    <img draggable="false" src={grabber} />
                </div>
            </div>
        </div >

        let rendered
        if (draggable) {
            rendered = <Draggable ref={ref => this.viewRef = ref} defaultClassName="draggable_absolute" onStop={this.onDragStop} onStart={this.onDragStart} onDrag={this.onDrag} position={initial ? position : undefined} grid={[25, 25]} bounds='parent'>
                {design}
            </Draggable >
        }
        else {
            rendered = <DragDropContainer
                targetKey="boxItem"
                dragData={{ label: name, index, uid, item }}
                onDrop={this.deleteMe}
                disappearDraggedElement={false}
                dragHandleClassName="outer"
            >
                <DropTarget
                    onHit={this.handleDrop}
                    targetKey="boxItem"
                >
                    {design}
                </DropTarget>
            </DragDropContainer>
        }



        return rendered
    }
}