import React, { Component } from 'react'
import { connect } from "react-redux"
import QuizView from './QuizView'
import { stepIds } from 'configs/enums'
import { activitiesEndpoints, routes } from 'configs/endpoints'
import activitiesActions from 'actions/activitiesActions'
import stepActions from 'actions/stepActions'
import { bindActionCreators } from 'redux'


const askingQuestions = [
    'How often do you currently do this activity?',
    'How often would you like to coordinate with this activity?',
    'Do you often help with the coordination of this activity?',
    'Are you open to new people you don\'t currently know doing this activity with you?',
    'Check off the activities that you sometimes help coordinate/initiate.'
]
const testQuestions = [
    {
        action: 'attend',
        activity: 'executive board editorial meetings',
        with: 'The Vector ',
        location: 'The Campus Center 4th Floor',
        member: '',
    },
    {
        action: 'attend',
        activity: 'executive board editorial meetings',
        with: 'The Vector ',
        location: 'The Campus Center 4th Floor',
        member: '',
    }
]

class QuizContainer extends Component {

    state = {
        initialValidation: true,
        activity: {
            questions: []
        },
        questions: [],
        activityType: '',
        validate: false,

    }

    frequencyAnswers = (property, value, index, flag) => {
        let questions = this.state.questions
        questions[flag].questions[index] = {
            ...questions[flag].questions[index],
            frequencyAnswers: {
                ...questions[flag].questions[index].frequencyAnswers,
                [property]: value,
            }
        }

        this.setState({
            questions,
            initialValidation: true,
            // validate: this.validCheck()
        })
        this.checking(questions)
    }

    checking = (q) => {
        let validated = true

        q.map(question => {
            question.questions.map(q => {
                if (q.frequencyAnswers) {
                    for (let key in q.frequencyAnswers) {
                        q.frequencyAnswers.open = "false"
                        if (q.frequencyAnswers[key] === "") {
                            validated = false
                        }

                    }
                }
            })

        })
        this.setState({ validate: validated })
    }

    questionsValidation = (key, value, activityType) => {
        if (!value) {
            return true
        }
        return value.trim() === ''
    }

    onNext = async () => {
        this.setState({
            initialValidation: false
        })
        let validated = true

        this.state.questions.map(question => {
            question.questions.map(q => {
                if (q.frequencyAnswers) {
                    for (let key in q.frequencyAnswers) {
                        if (question.name === "interestGroups") {
                            q.frequencyAnswers.open = "false"
                            if (this.questionsValidation(key, q.frequencyAnswers[key])) {
                                validated = false
                            }
                        }

                    }
                }
            })

        })
        if (validated) {
            this.state.questions.map(item => {
                this.props.activitiesActions.setActivityQuestions(item.questions, item.name)
                // this.props.history.replace(routes.desktop)
            })
            await this.send()
        }
    }

    send = () => {
        this.props.activitiesActions.sendActivityQuestions()
        this.props.history.push('interests')
    }

    onBack = () => {
        this.props.history.replace("/survey/activities/activities")
    }

    constructor(props) {
        super(props)
        props.stepActions.setActiveStep(stepIds.activities)
    }

    componentDidMount() {
        let questionsCopy = [];
        let activityCopy = { ...this.props.activities }


        for (let activity in this.props.activitySelectors) {
            if (this.props.activitySelectors[activity] === "true") {
                let act = JSON.parse(JSON.stringify(this.props.activities[activity]));
                let obj = { ...obj, questions: act.questions, name: activity }
                questionsCopy = [...questionsCopy, obj]
            }

        }

        this.setState({
            activity: activityCopy,
            questions: questionsCopy,
        })

        this.checking(questionsCopy)
    }

    validCheck = () => {
        let validated;
        this.state.questions.map(question => {
            question.questions.map(q => {
                if (q.frequencyAnswers) {
                    for (let key in q.frequencyAnswers) {
                        if (question.name === "interestGroups") {
                            q.frequencyAnswers.open = "false"
                            if (this.questionsValidation(key, q.frequencyAnswers[key])) {
                                validated = false
                                break
                            } else {
                                validated = true;
                                break
                            }
                        }

                    }
                }
            })

        })
        return validated
    }

    render() {
        const { check, questions, initialValidation, activityType, validate } = this.state
        return (
            <div>
                <div style={{ margin: '0 auto', width: '675px' }}>
                    <table style={{ marginLeft: '110px', width: '675px' }}>
                        <thead >
                            <tr>
                                <th></th>
                                <th className='quiz-asking-question' >How often do you do this activity?</th>
                                <th className='quiz-asking-question' style={{ paddingLeft: '40px' }}>How often would you like to do this activity?</th>
                                <th className='quiz-asking-question' style={{ paddingLeft: '40px' }}>Do you help with coordination of this activity?</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                {
                    questions ? questions.map((item, index) => (
                        <QuizView
                            flag={index}
                            name={item.name}
                            questions={item.questions}
                            askingQuestions={askingQuestions}
                            frequencyAnswers={this.frequencyAnswers}
                            onNext={this.onNext}
                            onBack={this.onBack}
                            initialValidation={initialValidation}
                            activityType={activityType}
                            check={check}
                            onCheck={this.onCheck}
                        />
                    )) : null
                }
                <div style={{ paddingTop: '190px' }}></div>
                <div className='activity-button-container'>
                    <div className={"rounded-button bcolor-pink"} onClick={this.onBack}>
                        <span className="rounded-button-text">Back</span>
                    </div>
                    {
                        validate ?
                            <div className={"rounded-button bcolor-pink"} onClick={this.onNext}>
                                <span className="rounded-button-text">Next</span>
                            </div>
                            :
                            <div className={"rounded-button color-color-disabled"} style={{ backgroundColor: '#e1e1e1' }} >
                                <span className="rounded-button-text">Next</span>
                            </div>
                    }
                </div>
            </div >


        )
    }
}


const mapStateToProps = (state) => ({
    activities: state.activities,
    activitySelectors: state.data.activitySelectors,

})

const mapDispatchToProps = (dispatch) => ({
    activitiesActions: bindActionCreators(activitiesActions, dispatch),
    stepActions: bindActionCreators(stepActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(QuizContainer)
