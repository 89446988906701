import _ from 'lodash'

import actionTypes from '../actions/actionTypes'


const campus = {
    paragraph1: '<span class="example-paragraph">We would like to know about the social <span class="example-paragraph-bold example-paragraph-underline">group</span> activities you do or have done with <span class="example-paragraph-purple">Campus Clubs and Organizations,</span> as well as the typical locations you do these activities.</span>'
    ,
    paragraph2: '<span class="example-paragraph">To successfully complete this survey the organizations, activities and locations have have to be unambiguous</span>',
    examples: [
        {
            text: '<span class="good-examples-paragraph">I <span class="color-orange">attend</span> <span class="color-pink-2">executive board editorial meetings </span> with <span class="color-teal">The Vector</span><span class="color-blue"> at Campus Center 4th floor.</span></span>',
            status: 'Yes'
        },
        {
            text: '<span class="good-examples-paragraph">I <span class="color-orange">play</span> <span class="color-pink-2">Basketball</span> with <span class="color-teal">The Theta Chi Fraternity</span> <span class="color-blue">at The WEC.</span></span>',
            status: 'No'
        },
        {
            text: '<span class="good-examples-paragraph">I <span class="color-orange">attend</span> <span class="color-pink-2">Movie Screenings</span> <span class="color-teal">with ACM SIGGRAPH</span> <span class="color-blue">at the Architecture Building.</span></span>',
            status: 'Yes'
        },
        {
            text: '<span class="good-examples-paragraph">I <span class="color-orange">attend</span> <span class="color-pink-2">pizza meetings</span> with <span class="color-teal">The Vector</span> <span class="color-blue">at Campus Center 4th floor</span></span>',
            status: 'Yes'
        }
    ],
    badExamples: [
        {
            text: ' I write a story for the vector in my dorm room (not a social group activity)'
        },
        {
            text: 'I attend frat parties somewhere in this country(Not a clear location)'
        },
        {
            text: 'I eat at Giovannis Pizza with students. ( Group of people not clear/specific)'
        }
    ],
    questions: [],

}

const work = {
    paragraph1: '<span class="example-paragraph">We would like to know about the social <span class="example-paragraph-bold example-paragraph-underline">group</span> activities you do or have done with <span class="example-paragraph-purple">Campus Clubs and Organizations,</span> as well as the typical locations you do these activities.</span>'
    ,
    paragraph2: '<span class="example-paragraph">To successfully complete this survey the organizations, activities and locations have have to be unambiguous</span>',
    examples: [
        {
            text: '<span class="good-examples-paragraph">I <span class="color-orange">attend</span> <span class="color-pink-2">team dinners </span> with <span class="color-teal">Dev Team</span><span class="color-blue"> at Sakura.</span></span>',
            status: ''
        },
        {
            text: '<span class="good-examples-paragraph">I <span class="color-orange">play</span> <span class="color-pink-2">Basketball</span> with <span class="color-teal">TA Group</span> <span class="color-blue">at The WEC.</span></span>',
            status: ''
        },
        {
            text: '<span class="good-examples-paragraph">I <span class="color-orange">watch</span> <span class="color-pink-2">Movies</span> with <span class="color-teal">Halal guys friends</span> <span class="color-blue">at the AMC theater.</span></span>',
            status: ''
        },
    ],
    badExamples: [
        {
            text: ' I write a story for the vector in my dorm room (not a social group activity)'
        },
        {
            text: 'I attend frat parties somewhere in this country(Not a clear location)'
        },
        {
            text: 'I eat at Giovannis Pizza with students. ( Group of people not clear/specific)'
        }
    ],
    questions: [],

}

const classRelated = {
    paragraph1: '<span class="example-paragraph">We would like to know about the <span class="example-paragraph-purple">class related social <span class="example-paragraph-bold example-paragraph-underline">group</span></span> activities you do or have done with, as well as the typical locations you do these activities. ',
    paragraph2: '<span class="example-paragraph">To successfully complete this survey the organizations, activities and locations have have to be unambiguous</span>',
    examples: [
        {
            text: '<span class="good-examples-paragraph">I <span class="color-orange">attend</span> <span class="color-pink-2">Class Project Meetings  </span> with my <span class="color-teal">IS 375 Group</span><span class="color-blue"> at Campus Center 4th floor.</span></span>',
        },
        {
            text: '<span class="good-examples-paragraph">I <span class="color-orange">attend</span> <span class="color-pink-2">Review Session</span> with my <span class="color-teal">Physics Section</span> <span class="color-blue">at Teirnan 505.</span></span>',
        },
        {
            text: '<span class="good-examples-paragraph">I <span class="color-orange">attend</span> <span class="color-pink-2">Tutoring Pannel</span> with <span class="color-teal">ACM</span> <span class="color-blue">at GITC 405.</span></span>',
        },
    ],
    badExamples: [
        {
            text: '  I write a story for the vector in my dorm room (not a social group activity)'
        },
        {
            text: 'I attend frat parties somewhere in this country(Not a clear location)'
        },
        {
            text: 'I eat at Giovannis Pizza with students. ( Group of people not clear/specific)'
        }
    ],
    questions: [],

}

const interestGroups = {
    paragraph1: '<span class="example-paragraph">We would now like to know about the <span class="example-paragraph-purple">interest-centered</span> social group-activities you do, or would like to do with other people. By interest-centered activities we mean things that you do with a group because you have a specific interest in the activity, rather than something you just do with them socially. For example, you might be really interested in playing beach volleyball and play it a lot over the summer with other people who you know because you play beach volleyball with them. In that case Beach Volleyball would be an Interest-Centered Activity.</span>'
    ,
    paragraph2: '<span class="example-paragraph"><span class="color-teal">Status</span> <span class="color-teal">action description</span> <span class="color-pink-2">activity name</span> <span class="color-blue">location it occurs.</span></span>',
    examples: [
        {
            text: '<span class="good-examples-paragraph"><span class="color-teal">I currently </span> <span class="color-orange">attend </span> <span class="color-pink-2">HCI Lab meetings</span><span class="color-blue"> at GITC 302.</span></span>',
            status: ''
        },
        {
            text: '<span class="good-examples-paragraph"><span class="color-teal">I currently </span> <span class="color-orange">attend </span> <span class="color-pink-2">Tech Talks</span><span class="color-blue"> at GITC 400.</span></span>',
            status: ''
        },
        {
            text: '<span class="good-examples-paragraph"><span class="color-teal">I currently </span> <span class="color-orange">attend </span> <span class="color-pink-2">recreational soccer</span><span class="color-blue"> at The WEC.</span></span>',
            status: ''
        },
        {
            text: '<span class="good-examples-paragraph"><span class="color-teal">I currently </span> <span class="color-orange">attend </span> <span class="color-pink-2">Governers Ball</span><span class="color-blue"> at Governers Island.</span></span>',
            status: ''
        }
    ],
    badExamples: [],
    questions: [],
}

export const initialState = {
    campus,
    classRelated,
    work,
    interestGroups,
}

export default (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.LOGOUT: {
            return initialState
        }

        case actionTypes.SET_ACTIVITY_QUESTIONS: {
            const { questions, activity } = action.payload

            let tempActivity = { ...state[activity] }
            tempActivity.questions = questions
            return _.assign({}, state, { [activity]: tempActivity })
        }

        case actionTypes.REPOPULATE_TEEUPS: {
            const updatedObjects = action.payload
            console.log(updatedObjects, "update objcet")
            const updatedState = {
                ...state,
                campus: {
                    ...state.campus,
                    questions: updatedObjects.campus
                },
                classRelated: {
                    ...state.classRelated,
                    questions: updatedObjects.classRelated
                },
                work: {
                    ...state.work,
                    questions: updatedObjects.work
                },
                interestGroups: {
                    ...state.interestGroups,
                    questions: updatedObjects.interestGroups
                }
            }
            return _.assign({}, state, updatedState)
        }

        // case actionTypes.CONTACT_INFO_SET: {
        //     return _.assign({}, state, action.payload)
        // }

        default:
            return state
    }
}