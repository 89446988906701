import _ from 'lodash'
import React from 'react'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
import BoxItem from './boxItem'
import InfiniteScroll from 'react-infinite-scroller'
import { sorting } from '../../utils/dataUtils';

const itemsPerPage = 20

export default class Box extends React.Component {

    constructor(props) {
        super(props)

        let items = [...props.contacts]

        let uid = props.uid;

        let initialItems = Math.min(itemsPerPage, items.length)

        this.searchTerm = ''

        this.state = {
            items: items,
            itemsFiltered: items,
            itemsShowing: items, // 20 items initial
            hasMore: items.length > initialItems,
            uid: uid,
        }

        this.loadMore = _.throttle(this.loadMore, 600)
        // this.onSearch = _.debounce((event) => this.onSearchRealtime(event), 500)
    }

    // onSearch = _.debounce(event => {
    //     let sections = this.prepareSections(searchTerm)
    //     this.setState({ sections, searchTerm })
    // }, 500)

    onSearch = _.debounce(value => {
        let itemsFiltered = this.state.itemsFiltered

        const regex = new RegExp(`(${value})`, 'ig')
        if (!value || value.length == 0) {
            itemsFiltered = this.state.items
        }
        // else if (value.includes(this.searchTerm)) {
        //     itemsFiltered = itemsFiltered.filter(item => {
        //         return item.filter
        //             && regex.test(item.filter);
        //     })
        // }
        else {
            itemsFiltered = this.state.items.filter(item => item.filter && regex.test(item.filter))
        }

        this.searchTerm = value

        let itemsShowing = itemsFiltered.slice(0, Math.min(itemsPerPage, itemsFiltered.length))
        // let itemsShowing = itemsFiltered
        this.setState({ itemsFiltered, itemsShowing, hasMore: true, })
    }, 500)

    handleDrop = (e) => {
        let items = this.state.items.slice();
        items.push({ ...e.dragData.item })
        this.setState({ items: items });
        e.containerElem.style.visibility = "hidden";
    }

    swap = (fromIndex, toIndex, dragData) => {
        let items = this.state.items.slice()
        const item = { ...dragData.item }
        items.splice(toIndex, 0, item)
        this.setState({ items: items })
    };

    kill = (uid) => {
        let updates = {}
        updates.items = this.killInList(this.state.items, uid)
        updates.itemsShowing = this.killInList(this.state.itemsShowing, uid)
        updates.itemsFiltered = this.killInList(this.state.itemsShowing, uid)

        this.setState(updates)
    }

    findItems = (uid) => {
        let item;
        let items = this.state.items.slice();
        const index = items.findIndex((item) => {
            return item.uid == uid
        });

        if (index !== -1) {
            items.splice(index, 1);
            item = items[index];
        }

        return item;
    }

    allow = (uid) => {
        let updates = {}
        updates.items = this.allowItems(this.state.items, uid)
        updates.itemsShowing = this.allowItems(this.state.itemsShowing, uid)
        updates.itemsFiltered = this.allowItems(this.state.itemsShowing, uid)
    }

    allowItems = (items, uid) => {
        items = items.slice();
        const index = items.findIndex((item) => {
            return item.uid == uid;
        })

        if (index !== -1) {
            items[index].draggable = true;
        }

        return items;
    }

    killInList = (items, uid) => {
        items = JSON.parse(JSON.stringify(items)).slice();
        const index = items.findIndex((item) => {
            return item.uid == uid
        });

        if (index !== -1) {
            // console.log(items[index], "item index")
            // items.splice(index, 1);
            // console.log(item.position, "position")
            items[index].draggable = false;
        }
        return items
    }

    loadMore = (page) => {

        // When need a reload, page for infinity scroll don't actually reset
        page = Math.ceil(this.state.itemsShowing.length / itemsPerPage)
        // console.log("load more " + page)
        let itemsShowing = [...this.state.itemsShowing]
        let lastItem = (page + 1) * itemsPerPage
        let items = this.state.itemsFiltered
        let len = items.length
        for (let i = page * itemsPerPage; i < len && i < lastItem; i++) {
            itemsShowing.push(items[i])
        }

        let hasMore = lastItem < len

        this.setState({ itemsShowing, hasMore })
    }
    returnDeletedContact = async (id) => {

        let items = [];
        items = this.state.itemsShowing;
        items.map(item => {
            if (item.uid === id) {
                item.draggable = true;

            }
        })
        items = sorting(items)
        this.setState({
            itemsShowing: items,
            items: items,
            itemsFiltered: items,
        })

        // this.props.deletedUid("");
    }

    updateState = async (items, initialItems) => {
        await this.setState({
            items: items,
            itemsFiltered: items,
            itemsShowing: items.slice(0, initialItems), // 20 items initial
        })
    }


    componentDidUpdate(prevProps) {

        if (this.state.uid !== this.props.uid) {
            console.log(prevProps.uid, "uidaaaaaaaaaaaaaaaaaaaaa")
            this.returnDeletedContact(this.props.uid)
            this.setState({ uid: this.props.uid })
        } else {
            if (prevProps.contacts.length !== this.props.contacts.length) {
                let items = [...this.props.contacts]
                console.log("A")
                console.log(prevProps.contacts.length, ":", this.props.contacts.length)
                let initialItems = Math.min(itemsPerPage, items.length)
                this.updateState(items, initialItems)
            }
        }

    }

    render() {
        /*
            Note the two layers of DropTarget. 
            This enables it to handle dropped items from 
            outside AND items dragged between boxes.
        */
        let { hasMore, itemsShowing, items } = this.state
        const { addContacts } = this.props
        // console.log(this.props.contacts, "contacts")
        // console.log(itemsShowing, "item showing")
        let a = this.props.contacts;
        console.log(itemsShowing, "itemShowing")
        let renderedItems = itemsShowing.map((item, index) => {
            // console.log(item, "item")
            if (item.draggable) {
                return (
                    <BoxItem
                        item={item}
                        key={item.uid}
                        uid={item.uid}
                        kill={this.kill}
                        updateDeleted={this.updateDeleted}
                        index={index}
                        swap={this.swap}
                        name={item.label}
                        image={''}
                    />
                )
            } else {
                return (
                    <div style={{
                        width: '95%',
                        margin: '0 auto',
                        borderRadius: "0.5rem",
                        padding: "0.5rem",
                        backgroundColor: "#f5f5f5",
                        border: "1px solid #e1e1e1",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }
                    }>
                        <div className="image" style={{
                            width: "2rem",
                            height: "2rem",
                            backgroundColor: "#f0f0f0",
                            borderRadius: "100rem"
                        }}>

                        </div>
                        <div className="content" style={{
                            marginLeft: "0.75rem",
                            marginRight: "0.75rem",
                        }}>
                            <div className="name">{item.label}</div>
                        </div>

                        <div className="spacer" />

                        <div className="grabber">
                            {/* <img draggable="false" src={grabber} /> */}
                        </div>
                    </div >
                    // <div className="boxDisabled">{item.label}</div>
                )
            }

        })

        return (
            <div className="box_container_tags" >
                <div className="box_title font-demibold-header">Contacts</div>
                <div className="box" ref={(ref) => this.scrollParentRef = ref}>
                    <div className="box_item_component">
                        {/* <div className={"item" + (hasTags ? ' tagged': '')}> */}
                        <div className="item searchbar" onClick={this.onTap}>
                            <div className="magnifier"></div>
                            <input onChange={event => this.onSearch(event.target.value)} className="text font-medium-prebig" placeholder="Search contacts "></input>
                        </div>
                    </div>
                    <div className="box_item_component" onClick={addContacts}>
                        {/* <div className={"item" + (hasTags ? ' tagged': '')}> */}
                        <div className="item searchbar">
                            <div className="user-plus-container">
                                <i className="user-plus"></i>
                            </div>
                            <div className='content'>Add a Contact</div>
                        </div>
                    </div>
                    <DropTarget
                        onHit={this.handleDrop}
                        targetKey="boxItem"
                        dropData={{ name: this.props.name }}
                    >

                        <InfiniteScroll
                            className="scroller"
                            pageStart={0}
                            loadMore={this.loadMore}
                            hasMore={hasMore}
                            useWindow={false}
                            getScrollParent={() => this.scrollParentRef}
                            threshold={10}
                            loader={<div className="loader" key={0}>Loading ...</div>}>
                            {renderedItems}
                        </InfiniteScroll>
                    </DropTarget>
                </div>
            </div >
        )
    }
}
