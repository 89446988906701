import _ from 'lodash'
import actionTypes from '../actions/actionTypes'
import backend from '../apis/backend'
import { sorting } from '../utils/dataUtils';
let globalGetState
let globalDispatch

const sendContactInfo = (emailUni, phone, countryCode) => {
    globalDispatch({ type: actionTypes.CLEAR_ERROR })

    // setTimeout(() => {
    //     globalDispatch({
    //         type: actionTypes.CONTACT_INFO_SENT, payload: {
    //             email1: {
    //                 id: 400,
    //                 value: 'la',
    //             },
    //             email2: {
    //                 id: 400,
    //                 value: 'la',
    //             },
    //             phoneNumber: {
    //                 id: 400,
    //                 value: 'la',
    //             },
    //         }
    //     })
    // }, 1000)

    backend.sendContactInfo(emailUni, phone, countryCode)
        .then(response => {
            let { result } = response;
            if (result.email1 && result.phoneNumber) {
                globalDispatch({ type: actionTypes.CONTACT_INFO_SENT, payload: response.result })
            } else {
                globalDispatch({ type: actionTypes.ERROR, payload: { contactInfoSendError: response.result } })
            }
        })
        .catch(error => {
            globalDispatch({ type: actionTypes.ERROR, payload: { contactInfoSendError: error ? error : 'Unknown error' } })
        })
}

const checkSync = () => {
    const userIdTest = globalGetState().data.userIdTest
    let isForSync = true
    backend.fetchUserInfo(userIdTest, { isForSync: isForSync })
        .then(response => {
            globalDispatch({ type: actionTypes.IS_CONTACT_SYNHRONED, payload: response })
        })
    // backend.fetchUserInfo() 
}

const verifyContactInfo = (data, flag) => {
    globalDispatch({ type: actionTypes.CLEAR_ERROR })

    backend.verifyContactInfo(data)
        .then(response => {
            if (flag === "email") {
                let payload = { flag: "email", res: response.email1 }
                globalDispatch({ type: actionTypes.CONTACT_INFO_VERIFY_SENT, payload: payload })
            } else {
                let payload = { flag: "phone", res: response.phoneNumber }
                globalDispatch({ type: actionTypes.CONTACT_INFO_VERIFY_SENT, payload: payload })
            }
            backend.setToken(response.token)
            // if (response.email1 && response.phoneNumber) {
            //     globalDispatch({ type: actionTypes.CONTACT_INFO_VERIFY_SENT })
            // }
            // else {
            //     console.log(response, "response")
            //     return Promise.reject('Failed to verify codes. Please check again and resend.')
            // }

        })
        .catch(error => {
            alert(error)
            globalDispatch({ type: actionTypes.ERROR, payload: { contactInfoVerifyError: error ? error : 'Unknown error' } })
        })
}

const formatDemographicsObjectForBackend = ({
    age, gender, orientation, growUpCity, liveCity, primaryLanguage, otherLanguages, relationshipStatus,
    studentStatus, majoring, internationalStudent, outofstateStudent, clubs
}) => {
    return [
        {
            "demographicId": 1,
            "answer": age
        },
        {
            "demographicId": 2,
            "answer": gender
        },
        {
            "demographicId": 3,
            "answer": orientation
        },
        {
            "demographicId": 4,
            "answer": growUpCity
        },
        {
            "demographicId": 5,
            "answer": liveCity
        },
        {
            "demographicId": 6,
            "answer": primaryLanguage
        },
        {
            "demographicId": 7,
            "answer": ""
        },
        {
            "demographicId": 8,
            "answer": otherLanguages.toString()
        },
        {
            "demographicId": 9,
            "answer": relationshipStatus
        },
        {
            "demographicId": 10,
            "answer": studentStatus
        },
        {
            "demographicId": 11,
            "answer": majoring
        },
        {
            "demographicId": 12,
            "answer": internationalStudent
        },
        {
            "demographicId": 13,
            "answer": outofstateStudent
        }
    ]
}

const formatActivitiesObjectForBackend = () => {
    const activities = globalGetState().activities
    const userId = globalGetState().user.userInfo.id
    let formattedActivities = {}
    for (let property in activities) {
        if (activities.hasOwnProperty(property)) {
            let formattedQuestions = []
            activities[property].questions.map(question => {
                formattedQuestions.push({
                    activityId: question.id,
                    action: question.action,
                    with: question.with,
                    location: question.location,
                    member: question.member,
                    checkOff: question.frequencyAnswers.checkOff,
                    status: question.status,
                    currentFrequency: question.frequencyAnswers.do,
                    expectedFrequency: question.frequencyAnswers.like,
                    isOpenToNewPeople: question.frequencyAnswers.open === 'Yes' ? true : false,
                    activity: question.activity,
                    help: question.frequencyAnswers.help
                })
            })
            formattedActivities[property] = formattedQuestions
        }
    }
    formattedActivities.userId = userId
    return formattedActivities
}

const formatBackendForActivitiesObject = (backendActivities) => {

    let activities = {
        campus: [],
        classRelated: [],
        interestGroups: [],
        work: [],
    }

    backendActivities.map(activity => {
        let category = formatCategoryName(activity.category)
        let activityObject = {
            id: activity.activityId,
            action: activity.action,
            activity: activity.activityname,
            frequencyAnswers: {
                // checkOff: activity.checkOff,
                do: activity.currentfrequency,
                help: activity.help,
                like: activity.expectedfrequency,
                // open: activity.isopentonewpeople ? 'Yes' : 'No',
            },
            location: activity.location,
            member: activity.ismember,
            // status: activity.status,
            with: activity.didwith
        }
        if (category === 'interestGroups') {
            activityObject.frequencyAnswers.checkOff = activity.checkoff ? 'true' : 'false'
            activityObject.frequencyAnswers.open = activity.isopentonewpeople ? 'Yes' : 'No'
            activityObject.status = activity.status
            delete activityObject.frequencyAnswers.help
        }
        activities[category].push(activityObject)
    })
    return activities
}

const formatCategoryName = (name) => {
    switch (name) {
        case 'CAMPUS': {
            return 'campus'
        }
        case 'CLASSRELATED': {
            return 'classRelated'
        }
        case 'INTERESTGROUPS': {
            return 'interestGroups'
        }
        case 'WORK': {
            return 'work'
        }
    }
}

const sendQuestions = (questions) => {
    const userId = globalGetState().user.userInfo.id
    backend.sendQuestions(questions, userId)
        .then()
        .catch(error => {
            console.log('demographics post error ', error)
        })
}

const sendActivityQuestions = () => {
    const formattedActivities = formatActivitiesObjectForBackend()
    backend.sendActivitiesQuestions(formattedActivities)
        .then(response => {
            console.log('activities post success ', response)
            const userId = globalGetState().user.userInfo.id
            backend.getActivitiesQuestions(userId)
                .then(response => {
                    console.log('activities GET success ', response)
                    globalDispatch({ type: actionTypes.SEND_ACTIVITY_QUESTIONS_END, payload: response.activities })
                })

            // globalDispatch({ type: actionTypes.SEND_ACTIVITY_QUESTIONS_END, payload: response.activitiesResult })
        })
        .catch(error => {
            console.log('activities post error ', error)
        })
}

const formatBasicsQuestions = (basicQuestions) => {
    let formatedQuestions = {}
    basicQuestions.map(question => {
        switch (question.demographicid) {
            case 1: {
                formatedQuestions.age = question.answer
                break
            }
            case 2: {
                formatedQuestions.gender = question.answer
                break
            }
            case 3: {
                formatedQuestions.orientation = question.answer
                break
            }
            case 4: {
                formatedQuestions.growUpCity = question.answer
                break
            }
            case 5: {
                formatedQuestions.liveCity = question.answer
                break
            }
            case 6: {
                formatedQuestions.primaryLanguage = question.answer
                break
            }
            case 7: {
                break
            }
            case 8: {
                formatedQuestions.otherLanguages = []
                break
            }
            case 9: {
                formatedQuestions.relationshipStatus = question.answer
                break
            }
            case 10: {
                formatedQuestions.studentStatus = question.answer
                break
            }
            case 11: {
                formatedQuestions.majoring = question.answer
                break
            }
            case 12: {
                formatedQuestions.internationalStudent = question.answer
                break
            }
            case 13: {
                formatedQuestions.outofstateStudent = question.answer
                break
            }
        }
    })
    return formatedQuestions
}

const getBasicsQuestions = (userId) => {
    backend.getQuestions(userId)
        .then(response => {
            const formattedDemographics = formatBasicsQuestions(response.demographics)
            console.log('response 3', formattedDemographics)
            globalDispatch({ type: actionTypes.FETCH_BASICS_QUESTIONS, payload: formattedDemographics })
        })
        .catch(error => {
            console.log('activities get error ', error)
        })
}

const getActivitiesQuestions = (userId) => {
    backend.getActivitiesQuestions(userId)
        .then(response => {
            let formatedObjects = formatBackendForActivitiesObject(response.activities)
            globalDispatch({ type: actionTypes.REPOPULATE_TEEUPS, payload: formatedObjects })
            globalDispatch({ type: actionTypes.SEND_ACTIVITY_QUESTIONS_END, payload: response.activities })
        })
        .catch(error => {
            console.log('activities get error ', error)
        })
}

// sorting

const editContactInfo = (body, flag) => {
    backend.editContactInfo(body)
        .then(response => {
            if (flag === "both") {
                globalDispatch({ type: actionTypes.EMAIL_CHANGE, payload: body })
                globalDispatch({ type: actionTypes.PHONE_CHANGE, payload: body })
            } else if (flag === "email") {
                globalDispatch({ type: actionTypes.EMAIL_CHANGE, payload: body })

            } else if (flag === "phone") {
                globalDispatch({ type: actionTypes.PHONE_CHANGE, payload: body })

            }
        })
}

const fetchContacts = (check) => {
    const userId = globalGetState().user.userInfo.id

    backend.fetchContacts(userId)
        .then(contacts => {
            console.log(contacts, "contacts")
            // SAVE_CONTACTS
            let formatContacts = contacts.data.map(contact => {
                let numbers = contact.phoneNumbers.map(item => `${numbers} ${item.number} `);
                let emails = contact.emails.map(item => `${emails} ${item.emails}`)
                let label = contact.name;
                let firtsNumber = contact.phoneNumbers.length ? contact.phoneNumbers[0].number : '';
                // If name is empty create new label 

                // if (label === "") {
                //     if (emails[0] !== "") {
                //         label = emails[0];
                //     } else {
                //         label = firtsNumber
                //     }
                // }

                let filter = label + numbers

                return {
                    label: label.trim(),
                    uid: contact.id,
                    firstName: contact.firstName,
                    phone: numbers,
                    filter: filter,
                    firstNumber: firtsNumber,
                    email: contact.emails
                }
            })
            globalDispatch({ type: actionTypes.SAVE_CONTACTS, payload: { contacts: sorting(formatContacts) } })
            if (check) {
                formatedContact()
            }
        })
        .catch(error => {
            console.log('fetchContacts error ', error)
        })
}

const editContact = (name, id) => {
    const userId = globalGetState().user.userInfo.id
    const contacts = globalGetState().data.contacts
    let body = {
        "_id": id,
        "emailAddresses": [
            {
                "label": "email",
                "email": "MILO",
            }],
        "familyName": "MILOS",
        "givenName": "MILOS",
        "phoneNumbers": [
            {
                "label": "phone",
                "number": '0'
            },
        ]
    }

    backend.editContact(body, userId)
        .then(response => {
            console.log("response", response)
        })
}

const sendContactGroups = (groups) => {
    const userId = globalGetState().user.userInfo.id

    let formattedGroups = groups.map(group => {
        return {
            name: group.name,
            members: Object.keys(group.lines),
        }
    })

    backend.sendContactGroups(userId, formattedGroups)
        .then(response => {
            console.log('sendContactGroups success ', response)
        })
        .catch(error => {
            console.log('sendContactGroups error ', error)
        })
}

const sendActivityGroups = (groups) => {
    const userId = globalGetState().user.userInfo.id

    backend.sendActivityGroups(userId, groups)
        .then(response => {
            console.log('sendActivityGroups success ', response)
        })
        .catch(error => {
            console.log('sendActivityGroups error ', error)
        })
}

const sendAttributeGroups = (groups) => {
    const userId = globalGetState().user.userInfo.id

    let formattedGroups = groups.map(group => {
        return {
            attributeId: group.index,
            members: group.contacts.map(contact => contact.uid),
        }
    })

    backend.sendAttributeGroups(userId, formattedGroups)
        .then(response => {
            console.log('sendAttributeGroups success ', response)
        })
        .catch(error => {
            console.log('sendAttributeGroups error ', error)
        })
}

const sendBucketGroups = (groups) => {
    const userId = globalGetState().user.userInfo.id

    let formattedGroups = Object.keys(groups).map(questionId => {
        let questionBuckets = groups[questionId]
        let formattedQuestionBuckets = Object.keys(questionBuckets).map(bucketId => {
            let contacts = questionBuckets[bucketId]
            let members = contacts.map(contact => {
                return contact.uid
            })
            return {
                bucketId,
                members,
            }
        })

        return {
            questionId,
            buckets: formattedQuestionBuckets,
        }

    })

    if (formattedGroups && formattedGroups.length > 0) {
        formattedGroups = formattedGroups[0].buckets
    }

    backend.sendBucketGroups(userId, formattedGroups)
        .then(response => {
            console.log('sendBucketGroups success ', response)
        })
        .catch(error => {
            console.log('sendBucketGroups error ', error)
        })
}

const fetchMajors = () => {
    backend.fetchMajors()
        .then(response => {
            let majors = response.majors.map(element => element.value)
            globalDispatch({ type: actionTypes.GET_MAJORS_END, payload: majors })
        })
        .catch(error => {
            console.log('fetchMajors error ', error)
        })
}

const fetchLanguages = () => {
    backend.fetchLanguages()
        .then(response => {
            let languages = response.languages.map(element => element.value)
            globalDispatch({ type: actionTypes.GET_LANGUAGES_END, payload: languages })
        })
        .catch(error => {
            console.log('fetchLanguages error ', error)
        })
}

const formatContacts = (name, email, phone, userId, countryCode) => {

    if (email === "") {
        return {
            "userId": userId,
            "contacts": [{
                "emailAddresses": [],
                "familyName": "",
                "givenName": name,
                "phoneNumbers": [
                    {
                        "label": "phone",
                        "number": phone
                    },
                ],
                "countryCode": countryCode
            }]
        }
    }

    if (phone === "") {
        return {
            "userId": userId,
            "contacts": [{
                "emailAddresses": [
                    {
                        "label": "email",
                        "email": email,
                    }
                ],
                "familyName": "",
                "givenName": name,
                "phoneNumbers": [],
            }]
        }
    }

    if (email !== "" && phone !== "") {
        return {
            "userId": userId,
            "contacts": [{
                "emailAddresses": [
                    {
                        "label": "email",
                        "email": email,
                    }
                ],
                "familyName": "",
                "givenName": name,
                "phoneNumbers": [
                    {
                        "label": "phone",
                        "number": phone
                    },
                ],
                "countryCode": countryCode
            }]
        }
    }
}

const addContact = (name, email, phone, countryCode) => {
    const userId = globalGetState().user.userInfo.id
    const formattedContacts = formatContacts(name, email, phone, userId, countryCode)
    backend.syncContacts(formattedContacts, userId)
        .then(success => {
            fetchContacts(true)
        })
        .catch(error => {
            console.log('contacts error ', error)
        })
}

const getRecommendedTeeUp = (contactIds) => {
    const userId = globalGetState().user.userInfo.id
    backend.getRecommendedTeeUp(userId, contactIds)
        .then(({ activity }) => {
            globalDispatch({ type: actionTypes.GET_RECOMMENDED_TEEUP_SUCCESS, payload: { activity, contactIds } })
        })
        .catch(error => {
            console.log('get recommended teeup error ', error)
            globalDispatch({ type: actionTypes.GET_RECOMMENDED_TEEUP_ERROR, payload: { error } })
        })
}

const getTeeUp = () => {
    const userId = globalGetState().user.userInfo.id
    backend.getTeeUp(userId)
        .then((response) => {
            let teeupsLength = response.teeups.length;
            console.log(teeupsLength, "TEEUPSLENGHT")
            globalDispatch({ type: actionTypes.GET_TEEUP_RESPONSE, payload: { teeupsLength } })
        })
        .catch(error => {
            console.log('get recommended teeup error ', error)
            // globalDispatch({ type: actionTypes.GET_TEEUP_RESPONSE, payload: { error } })
        })
}

const postDraftedTeeUp = (name, location, inviteMessage, contactIds) => {
    const userId = globalGetState().user.userInfo.id
    backend.postDraftedTeeUp(userId, name, location, inviteMessage, contactIds)
        .then(({ message }) => {
            globalDispatch({ type: actionTypes.POST_DRAFTED_TEEUP_SUCCESS, payload: { message } })
        })
        .catch(error => {
            console.log('post drafted teeup error ', error)
            globalDispatch({ type: actionTypes.POST_DRAFTED_TEEUP_ERROR, payload: { error } })
        })
}

const formatPayment = (address, addressType) => {
    return {
        'recipient': {
            "address": address,
            "addressType": addressType,
            "paymentCategory": "TELLUS",
            "amount": "0.01"
        }

    }
}

const addPayment = (address, addressType) => {
    const userId = globalGetState().user.userInfo.id;
    const formattedPayment = formatPayment(address, addressType);

    backend.postPayment(formattedPayment, userId)
        .then(response => {
            let message;
            let paymentStatus = response.transaction.map(item => {
                switch (item.transactionstatus) {
                    case "INSUFFICIENT_FUNDS":
                        return "Insufficient funds"
                    case "SUCCESS":
                        return "Success"
                    case "":
                        return "Something is wrong!"
                }
            })
            globalDispatch({ type: actionTypes.PAYMENT_STATUS, payload: paymentStatus })
        })
        .catch(error => {
            console.log('payment error', error);
        });
}

const postDesktopState = (state) => {
    const userId = globalGetState().user.userInfo.id
    backend.postDesktopState(state, userId)
        .catch(error => {
            console.log('POST desktop error ', error)
        })
}

const getDesktopState = (userId) => {
    backend.getDesktopState(userId)
        .then(state => {
            const parsedState = JSON.parse(state.state[0].state)
            globalDispatch({ type: actionTypes.DESKTOP_SAVE, payload: parsedState })
        })
        .catch(error => {
            console.log('POST desktop error ', error)
        })
}


// TEST 
const getUserInterests = () => {
    const userId = globalGetState().user.userInfo.id

    backend.listInterests(userId)
        .then(response => {
            globalDispatch({ type: actionTypes.GET_INTERESTS, payload: response })
        })
}
const searchInterests = (value) => {
    backend.searchInterests(value)
        .then(response => {
            globalDispatch({ type: actionTypes.COLLECT_INTERESTS, payload: response })
        })
}

const followInterests = (body) => {
    const userId = globalGetState().user.userInfo.id
    console.log(body, "body")
    backend.followInterests(userId, body)
        .then(response => {
            globalDispatch({ type: actionTypes.GET_INTERESTS, payload: response })

        })
}
const unFollowInterests = (id) => {
    const userId = globalGetState().user.userInfo.id
    backend.unFollowInterests(userId, id)
        .then(response => {
            globalDispatch({ type: actionTypes.GET_INTERESTS, payload: response })
        })
}

const createInterests = (body) => {
    const userId = globalGetState().user.userInfo.id
    backend.createInterests(userId, body)
        .then(response => {
            let id = response.id;
            let body = {
                interestIds: [
                    id
                ]
            }
            console.log(id, "id")
            followInterests(body)
        }).catch(error => console.log(error, "error"))
}



const formatedContact = () => {
    const boxItems = globalGetState().desktop.boxItems;
    const contacts = globalGetState().data.contacts;
    console.log(contacts.length);
    let obj;
    let arr = [];

    for (let cont = 0; cont < contacts.length; cont++) {
        if (boxItems && boxItems.length) {
            for (let items = 0; items < boxItems.length; items++) {
                if (boxItems[items].uid === contacts[cont].uid) {
                    obj = JSON.parse(JSON.stringify(contacts[cont]));
                    obj = { ...obj, draggable: false }
                    break
                } else {
                    obj = JSON.parse(JSON.stringify(contacts[cont]));
                    obj = { ...obj, draggable: true }
                }
            }
        } else {
            obj = JSON.parse(JSON.stringify(contacts[cont]));
            obj = { ...obj, draggable: true }
        }

        arr.push(obj)

    }

    // contacts.map(item => {
    //     if (boxItems.length !== 0) {
    //         boxItems.map(boxItem => {
    //             if (item.uid === boxItem.uid) {
    //                 obj = JSON.parse(JSON.stringify(item));
    //                 obj = { ...obj, draggable: false }
    //             } else {
    //                 obj = JSON.parse(JSON.stringify(item));
    //                 obj = { ...obj, draggable: true }
    //             }
    //         })
    //     } else {
    //         obj = JSON.parse(JSON.stringify(item));
    //         obj = { ...obj, draggable: true }
    //     }

    //     arr.push(obj)
    // })


    // contacts.map(contact => {
    //     obj = JSON.parse(JSON.stringify(contact));
    //     obj = { ...obj, draggable: true }
    //     arr.push(obj)
    // })

    // for (let ar = 0; ar < arr.length; ar++) {
    //     for (let item = 0; item < boxItems.length; item++) {
    //         if (arr[ar].uid === boxItems[item].arr) {
    //             console.log("uso")
    //             arr[ar].draggable = false
    //             console.log(arr[ar])
    //         }

    //     }
    //     // boxItems.map(item => {
    //     //     if (arr[ar].udi === item.uid) {
    //     //         console.log("uss")
    //     //         arr[ar].draggable = false
    //     //     }
    //     // })
    // }


    // for (let contact = 0; contact < contacts.length; contact++) {
    //     if (boxItems.length) {
    //         for (let item = 0; item < boxItems.length; item++) {
    //             if (contacts[contact].uid === boxItems[item].uid) {
    //                 obj = { ...obj, draggable: false }
    //                 console.log("usao u if", contacts[contact])
    //                 console.log(obj, "obj u if-u")
    //             } else {
    //                 obj = { ...obj, draggable: true }
    //             }
    //         }
    //     } else {
    //         obj = { ...obj, draggable: true }
    //     }
    //     console.log(obj, "obj")
    //     arr.push(obj)
    // }

    // contacts.map(contact => {
    //     obj = JSON.parse(JSON.stringify(contact));
    //     if (boxItems.length) {
    //         console.log(boxItems.length, "length")
    //         boxItems.map(item => {
    //             if (contact.uid === item.uid) {
    //                 console.log("uso u if")
    //                 obj = { ...obj, draggable: false }
    //             } else {
    //                 console.log("uso u else")
    //                 obj = { ...obj, draggable: true }
    //             }
    //         })
    //     } else {
    //         obj = { ...obj, draggable: true }
    //     }

    //     arr.push(obj)

    // })


    // arr.map(test => {
    //     if (test.draggable === true) {
    //         console.log(test, "FALSE")
    //     }
    // })

    // console.log(boxItems, contacts, "contactsaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
    // console.log(arr.length, contacts.length, "lenght compare")

    let arrTemp1 = [];


    arr.map(contactItem => {
        if (contactItem.label === "") {
            if (contactItem.email.length) {
                contactItem.label = contactItem.email[0]
            } else {
                contactItem.label = contactItem.phone[0].replace("undefined ", "")
                // console.log('uso')
                arrTemp1.push(contactItem);
                // console.log(contactItem.phone[0].replace("undefined", ""))
            }
        }
    })


    // for (let i = 0; i < arrTemp1.length; i++) {
    //     arr.splice(i, 1)
    //     // console.log("uso")
    //     arr.push(arrTemp1[i])
    // }


    globalDispatch({ type: actionTypes.SET_FORMATED_CONTACT, payload: arr })

}


export default function middleware({ dispatch, getState }) {

    globalGetState = getState
    globalDispatch = dispatch

    return next => action => {

        switch (action.type) {

            case 'persist/REHYDRATE': {

                if (action.payload && action.payload.user && action.payload.user.credentials && action.payload.user.credentials.idToken) {
                    backend.setToken(action.payload.user.credentials.idToken)
                }

                break
            }

            case actionTypes.GET_DEMOGRAPHICS_META: {
                fetchMajors()
                fetchLanguages()
                break
            }

            case actionTypes.GET_MAJORS: {
                break
            }

            case actionTypes.CONTACT_INFO_SEND: {

                let { emailUni, phone, countryCode } = action.payload
                const { email1, phoneNumber } = getState().data
                sendContactInfo(emailUni, phone, countryCode)

                break
            }

            case actionTypes.CONTACT_INFO_VERIFY: {

                const { code, flag } = action.payload
                let { email1, phoneNumber } = getState().data
                if (flag === "email") {
                    verifyContactInfo({
                        email1: {
                            id: email1.id,
                            code: code,
                        },
                    }, flag)
                } else {
                    verifyContactInfo({
                        phoneNumber: {
                            id: phoneNumber.id,
                            code: code,
                        }
                    }, flag);
                }
                break
            }

            case actionTypes.SEND_BASICS_QUESTIONS: {
                const { questions } = action.payload
                sendQuestions(formatDemographicsObjectForBackend(questions))
                break
            }
            case actionTypes.SEND_ACTIVITY_QUESTIONS: {
                sendActivityQuestions()
                break
            }

            case actionTypes.FETCH_CONTACTS: {
                fetchContacts(false)
                break
            }
            case actionTypes.FORMAT_CONTACT: {
                formatedContact()
                break;
            }
            case actionTypes.SEND_ACTIVITY_GROUPS: {
                sendActivityGroups(action.payload)
                break
            }

            case actionTypes.SEND_CONTACT_GROUPS: {
                sendContactGroups(action.payload)
                break
            }

            case actionTypes.SEND_BUCKET_GROUPS: {
                sendBucketGroups(action.payload)
                break
            }

            case actionTypes.SEND_ATTRIBUTE_GROUPS: {
                sendAttributeGroups(action.payload)
                break
            }

            case actionTypes.ADD_CONTACT: {
                const { name, email, phone, countryCode } = action.payload
                addContact(name, email, phone, countryCode)
                break
            }

            case actionTypes.EDIT_CONTACT: {
                const { name, id } = action.payload
                editContact(name, id);
                break;
            }

            case actionTypes.FETCH_TEELUS_INFORMATION: {
                const { userId } = action.payload
                getBasicsQuestions(userId)
                getActivitiesQuestions(userId)
                break
            }
            case actionTypes.ADD_PAYMENT: {
                const { address, addressType } = action.payload
                addPayment(address, addressType);
                break;
            }

            case actionTypes.GET_RECOMMENDED_TEEUP: {
                const { contactIds } = action.payload
                getRecommendedTeeUp(contactIds)
                break
            }

            case actionTypes.GET_TEEUP: {
                const { contactIds } = action.payload
                getTeeUp(contactIds)
                break
            }

            case actionTypes.POST_DRAFTED_TEEUP: {
                const { name, location, inviteMessage, contactIds } = action.payload
                postDraftedTeeUp(name, location, inviteMessage, contactIds)
                break
            }

            case actionTypes.POST_DESKTOP_STATE: {
                postDesktopState(action.payload)
                break
            }
            case actionTypes.GET_DESKTOP_STATE: {
                const { userId } = action.payload
                getDesktopState(userId)
                break
            }

            case actionTypes.CONTACT_INFO_CHANGE: {
                const { body, flag } = action.payload
                editContactInfo(body, flag)
                break;
            }
            case actionTypes.GET_USER_INTERESTS: {
                getUserInterests()
                break;
            }

            case actionTypes.SEARCH_INTERESTS: {
                searchInterests(action.payload)
                break;
            }
            case actionTypes.FOLLOW_INTERESTS: {
                followInterests(action.payload)
                break;
            }
            case actionTypes.CREATE_INTERESTS: {
                createInterests(action.payload)
                break;
            }
            case actionTypes.UNFOLLOW_INTERESTS: {
                unFollowInterests(action.payload)
                break;
            }
            case actionTypes.CHECK_IS_SYNCHRONED: {
                checkSync();
                break
            }
        }

        // Call the next dispatch method in the middleware chain.
        let returnValue = next(action)

        return returnValue
    }
}
