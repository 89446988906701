import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import ActivitiesSelectorView from './ActivitiesSelectorView'
import activitiesActions from 'actions/activitiesActions'
import stepActions from 'actions/stepActions'
import { stepIds } from 'configs/enums'

class ActivitiesSelectorContainer extends Component {

  onNext = () => {

    const { texts } = this.state;

    texts.map(item => {
      if (item.selected) {
        this.props.activitiesActions.setActivitySelector(item.name, item.selected)
      }
    })

    this.props.history.push('activities')

  }

  onBack = () => {
    this.props.history.replace("/survey/basics")
  }
  onValueChange = (event, name) => {
    let activities = this.state.texts;
    console.log(event.target.value, "event")

    if (event.target.value === "true") {
      this.setState({ count: this.state.count + 1 })
    } else {
      if (this.state.count !== 0) {
        this.setState({ count: this.state.count - 1 })

      }
    }

    activities.map(item => {
      if (item.name === name)
        item.selected = event.target.value
    })

    this.setState({ texts: activities })
  }


  formatText = () => {
    let count = 0;
    let { campus, classRelated, interestGroups, work } = this.props.activitySelectors;
    let activitiesText = [
      campus = {
        topText: 'CAMPUS CLUBS and ORGANIZATIONS',
        secoundaryText: '[e.g. The Vector and Student Senate]',
        bottomText: 'Do you engage in social group activities with members of campus clubs or organizations?',
        selected: campus,
        name: "campus"
      },
      classRelated = {
        topText: 'Classmates',
        secoundaryText: '',
        bottomText: 'Do you engage in social group activities with members of classes?',
        selected: classRelated,
        name: "classRelated"
      },
      work = {
        topText: 'Workmates on or near campus',
        secoundaryText: '',
        bottomText: 'Do you engage in social group activities with workmates?',
        selected: work,
        name: "work"
      },
      interestGroups = {
        topText: 'Interest-based social group activities with other students',
        secoundaryText: '[e.g. Pick-up Basketball, Online Gaming with campus friends, Ultimate Frisbee]',
        bottomText: 'Do you engage in social group activites that you are interested in with other students? ',
        selected: interestGroups,
        name: "interestGroups"
      },
    ];

    activitiesText.map(item => {
      if (item.selected === "true") {
        count += 1;
      }
    })

    this.setState({ texts: activitiesText, count: count })

  }

  constructor(props) {
    super(props)

    const activityType = props.location.pathname.split("/").pop().replace('Selector', '')

    this.state = {
      paragraph: '',
      topText: '',
      examples: [],
      bottomText: '',
      activity: '',
      status: props.activitySelectors[activityType],
      activityType,
      count: 0,
    }

    props.stepActions.setActiveStep(stepIds.activities)
  }

  componentDidMount() {
    this.formatText()
  }

  render() {

    const { texts, count } = this.state
    return (
      texts ?
        <div>
          {
            texts.map(item => (
              <ActivitiesSelectorView
                onValueChange={(event, name) => this.onValueChange(event, name)}
                examples={item.examples}
                secoundaryText={item.secoundaryText}
                topText={item.topText}
                status={item.selected}
                bottomText={item.bottomText}
                name={item.name}
              />
            ))
          }

          <div style={{ paddingTop: '137px' }} />
          <div className='activity-button-container'>
            <div className={"rounded-button bcolor-pink"} onClick={this.onBack}>
              <span className="rounded-button-text">Back</span>
            </div>
            {
              count < 2 ?
                <div className={"rounded-button bcolor-disabled"} >
                  <span className="rounded-button-text">Next</span>
                </div>
                :
                <div className={"rounded-button bcolor-pink"} onClick={this.onNext}>
                  <span className="rounded-button-text">Next</span>
                </div>
            }

          </div>
        </div>
        : null
    )
  }
}


const mapStateToProps = (state) => ({
  activities: state.activities,
  activitySelectors: state.data.activitySelectors,
})

const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(stepActions, dispatch),
  activitiesActions: bindActionCreators(activitiesActions, dispatch),

})

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesSelectorContainer)