import React from 'react'
import Select from 'react-select'
import './Basics.css'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

const BasicsView = ({
    writeValue, checkAge, age, growUpCity, liveCity, gender, orientation, primaryLanguage, otherLanguages, multipleSelect,
    relationshipStatus, studentStatus, majoring, internationalStudent, outofstateStudent, speaksAdditionalLanguage,
    onNext, languages, majors, isValid, changeLocation, handleSelect,
}) => {

    let international = internationalStudent === "" ? "false" : internationalStudent;

    return (
        <div className="basics-container">
            <fieldset className="section-fieldset">
                <legend className="section-header"
                >Demographics
                </legend>

                <div className="input-container">
                    <span className="label-for-input">What is your age?</span>
                    <input value={age} onChange={(event) => (event.target.validity.valid) ? writeValue(event, 'age') : writeValue('', age)}
                        onBlur={(event) => checkAge(event)} className="input-form input-form-text" placeholder="Enter age..." type="text" pattern="[0-9]*" />
                </div>

                <div className="input-container">
                    <span className="label-for-input">What best describes your gender?</span>
                    <select className={"input-form select-form " + (gender === '' && ' options-placeholder')}
                        value={gender} onChange={(event) => writeValue(event, 'gender')}>
                        <option value="" disabled selected hidden>Select...</option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                        <option value='other'>Other</option>
                        <option value='dontsay'>Rather not say</option>
                    </select>
                </div>
                {/* 
                <div className="input-container">
                    <span className=" label-for-input">What is your sexual orientation?</span>
                    <select className={"input-form select-form " + (orientation === '' && ' options-placeholder')}
                        value={orientation} onChange={(event) => writeValue(event, 'orientation')}>
                        <option value="" disabled selected hidden>Select...</option>
                        <option value='men'>I am attracted to men</option>
                        <option value='women'>I am attracted to women</option>
                        <option value='both'>I am attracted to both</option>
                        <option value='dontsay'>I would rather not say</option>
                        <option value='other'>Other</option>
                    </select>
                </div> */}

                <div className=" input-container">

                    <span className=" label-for-input">Where did you grow up?</span>
                    <PlacesAutocomplete
                        value={growUpCity}
                        onChange={address => changeLocation(address, 'growUpCity')}
                        onSelect={address => handleSelect(address, 'growUpCity')}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: " Enter a city...",
                                        className: 'input-form input-form-text',
                                    })}

                                />
                                <div className="autocomplete-dropdown-container">
                                    {suggestions.map(suggestion => {
                                        const className = 'suggestion-item'
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>

                <div className=" input-container">
                    <span className=" label-for-input">Where do you live now??</span>
                    <PlacesAutocomplete
                        value={liveCity}
                        onChange={address => changeLocation(address, 'liveCity')}
                        onSelect={address => handleSelect(address, 'liveCity')}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: " Enter a city...",
                                        className: 'input-form input-form-text',
                                    })}

                                />
                                <div className="autocomplete-dropdown-container">
                                    {suggestions.map(suggestion => {
                                        const className = 'suggestion-item'
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>

                <div className=" input-container">
                    <span className=" label-for-input">What is your primary language?</span>
                    <select className={"input-form select-form " + (primaryLanguage === '' && ' options-placeholder')}
                        value={primaryLanguage} onChange={(event) => writeValue(event, 'primaryLanguage')}>
                        <option value="" disabled selected hidden>Select...</option>
                        {languages.map((language, index) => <option key={index} value={language}>{language}</option>)}
                    </select>

                </div>

                <div className=" input-container radio-padding">
                    <span className=" label-for-input label-for-input-radio">Do you speak any other language?</span>
                    <div className=" input-form-radio">
                        <label className=" input-radio-label">
                            <input className=" radio-input" value={true} checked={speaksAdditionalLanguage == 'true'}
                                onChange={(event) => writeValue(event, 'speaksAdditionalLanguage')}
                                name=" additionalLanguageCheck" type="radio" /> Yes </label>
                        <label className=" input-radio-label">
                            <input
                                className=" radio-input" value={false} checked={speaksAdditionalLanguage != 'true'}
                                onChange={(event) => writeValue(event, 'speaksAdditionalLanguage')}
                                name=" additionalLanguageCheck" type="radio" /> No </label>
                    </div>
                </div>

                {
                    speaksAdditionalLanguage == 'true' &&

                    <div className=" input-container">
                        <div className=" left-text-container">
                            <span className=" label-for-input">Do you speak any other language?</span>
                            <span
                                className=" label-for-input label-for-information">(ctrl-click to select multiple)</span>
                        </div>
                        <Select
                            className="multi-select"
                            options={languages.map(language => ({ value: language, label: language }))}
                            isMulti
                            value={otherLanguages.map(language => ({ value: language, label: language }))}
                            onChange={values => multipleSelect(values, 'otherLanguages')}
                        />
                    </div>
                }

                <div className=" input-container">
                    <span className=" label-for-input">What is your relationship status?</span>
                    <select className={"input-form select-form " + (relationshipStatus === '' && ' options-placeholder')}
                        value={relationshipStatus} onChange={(event) => writeValue(event, 'relationshipStatus')}>
                        <option value="" disabled selected hidden>Select...</option>
                        <option>Single</option>
                        <option>In a relationship</option>
                        <option>Engaged</option>
                        <option>Married</option>
                        <option>It's complicated</option>
                        <option>In an open relationship</option>
                        <option>Widowed</option>
                        <option>In a domestic partnership</option>
                        <option>In a civil union</option>
                    </select>
                </div>

            </fieldset>

            <fieldset className="section-fieldset">
                <legend className=" section-header">Education</legend>

                <div className=" input-container">
                    <span className=" label-for-input">What is your current student status?</span>

                    <select className={"input-form select-form " + (studentStatus === '' && ' options-placeholder')}
                        value={studentStatus} onChange={(event) => writeValue(event, 'studentStatus')}>
                        <option value="" disabled selected hidden>Select...</option>
                        <option value={'1'}>1st Year/Freshman</option>
                        <option value={'2'}>2nd Year/Sophomore</option>
                        <option value={'3'}>3rd Year/Junior</option>
                        <option value={'4'}>4th Year +/Senior</option>
                        <option value={'master'}>Masters</option>
                        <option value={'phd'}>Ph.D</option>
                        <option value={'notatstudent'}>I am not a student</option>
                    </select>

                </div>

                <div className=" input-container">
                    <span className=" label-for-input">What are you majoring in?</span>
                    <select className={"input-form select-form " + (majoring === '' && ' options-placeholder')}
                        value={majoring} onChange={(event) => writeValue(event, 'majoring')}>

                        <option value="" disabled selected hidden>Select...</option>
                        {majors.map((major, index) => <option key={index} value={major}>{major}</option>)}
                    </select>
                </div>

                {/*<div className=" input-container">*/}
                {/*<div className=" left-text-container">*/}
                {/*<span*/}
                {/*className=" label-for-input">What campus clubs and organizations are you a member of?</span>*/}
                {/*<span*/}
                {/*className=" label-for-input label-for-information">(ctrl-click to select multiple)</span>*/}
                {/*</div>*/}
                {/*<select multiple="multiple" className="input-form custom-scroll"*/}
                {/*onChange={(event) => multipleSelect(event, 'clubs')}>*/}
                {/*<option>Actuarial Society</option>*/}
                {/*<option>American Chemical Society</option>*/}
                {/*<option>Alpha Sigma Phi Fraternity</option>*/}
                {/*<option>American Water Works Association</option>*/}
                {/*<option>African Student Association</option>*/}
                {/*<option>American Institute of Aeronautics and Astronautics</option>*/}
                {/*<option>Alpha Sigma Tau Sorority</option>*/}
                {/*<option>BAPS Christian Fellowship</option>*/}
                {/*</select>*/}
                {/*</div>*/}

                <div className=" input-container radio-padding">
                    <span className=" label-for-input label-for-input-radio">Are you an international student?</span>
                    <div className=" input-form-radio">
                        <label className=" input-radio-label">
                            <input className=" radio-input" value={true} checked={internationalStudent == 'true'}
                                onChange={(event) => writeValue(event, 'internationalStudent')}
                                name=" isInternationalStudent" type="radio" /> Yes </label>
                        <label className=" input-radio-label">
                            <input
                                className=" radio-input" value={false} checked={internationalStudent != 'true'}
                                onChange={(event) => writeValue(event, 'internationalStudent')}
                                name=" isInternationalStudent" type="radio" /> No </label>
                    </div>
                </div>

                {international == 'false'
                    &&
                    <div className="input-container radio-padding">
                        <span className="label-for-input label-for-input-radio">Are you an out-of-state student?</span>
                        <div className="input-form-radio">
                            <label className="input-radio-label">
                                <input name="isOutOfStateStudent" value={true} checked={outofstateStudent == 'true'}
                                    className="radio-input"
                                    onChange={(event) => writeValue(event, 'outofstateStudent')}
                                    type="radio" /> Yes </label>
                            <label className="input-radio-label">
                                <input name="isOutOfStateStudent" value={false} checked={outofstateStudent != 'true'}
                                    className="radio-input"
                                    onChange={(event) => writeValue(event, 'outofstateStudent')}
                                    type="radio" /> No </label>
                        </div>
                    </div>
                }

            </fieldset>

            <div className="buttons-container">
                <div className={"rounded-button bcolor-pink" + (onNext ? '' : ' bcolor-disabled')} style={{ marginRight: '300px' }} onClick={onNext}>
                    <span className="rounded-button-text">Next</span>
                </div>
            </div>

        </div>
    )
}

export default BasicsView