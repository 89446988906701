import React from 'react'
import ReactDOM from 'react-dom'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
import BoxItem from './boxItem'
import GroupItem from './groupItem'
import uuidv1 from 'uuid/v1'
import { Rnd } from 'react-rnd'
import { getTagColor } from 'utils/stylesUtils'
import { Dropdown, Menu } from 'antd'
import { Resizable, ResizableBox } from 'react-resizable';

import LineTo from 'react-lineto'
import ContactModal from './contactModal';

export default class Box extends React.Component {
    constructor(props) {

        super(props)

        let { groups } = props
        this.state = {
            lines: this.initializeLines(groups),
            offset: undefined,
            itemHeight: 0,
            activeGroupUid: null,
            killUid: "",
            isGroup: false,
            minDesktopWidth: 100,
            contactModalOpen: false,
            email: '',
            id: '',
            label: '',
            name: '',
            boxItemsModal: [],
            attr: [],
            pageType: '',
            current: 0,
        }

        if (props.refBoxDesktop) {
            props.refBoxDesktop(this)
        }

        this.selectedGroupRef = null
    }

    translateGlobalXYToLocal = ({ x, y }) => {
        let { offset } = this.state

        if (!offset) {
            var rect = ReactDOM.findDOMNode(this)
                .getBoundingClientRect()
            offset = { x: rect.x, y: rect.y }
            // this.setState({ offset })
        }

        let position = {
            x: x - offset.x,
            y: y - offset.y,
        }
        return position
    }

    onDragStop = (e, itemUpdate) => {
        let position = this.translateGlobalXYToLocal(e)
        console.log("moved to position", position, itemUpdate)
        let items = [...this.props.items]
        itemUpdate = { ...itemUpdate }
        // position.y -= this.state.itemHeight
        itemUpdate.position = position
        for (let i = 0, len = items.length; i < len; i++) {
            if (itemUpdate.uid === items[i].uid) {
                items[i] = itemUpdate
                break
            }
        }
        this.props.onUpdateItems(items)
    }

    onDragStopGroup = (e, groupUpdate) => {
        let position = this.translateGlobalXYToLocal(e)
        console.log("moved to position", position, groupUpdate)
        let groups = [...this.props.groups]
        groupUpdate = { ...groupUpdate }
        groupUpdate.position = position
        for (let i = 0, len = groups.length; i < len; i++) {
            if (groupUpdate.uid === groups[i].uid) {
                groups[i] = groupUpdate
                break
            }
        }
        this.props.onGroupsModified(groups)
    }

    handleDrop = (e) => {
        let items = [...this.props.items]

        let { itemHeight, itemWidth } = this.state
        let { tag } = this.props

        let position = this.translateGlobalXYToLocal(e)
        position.y -= itemHeight

        let tags = tag ? [tag] : []

        items.push({ label: e.dragData.label, uid: e.dragData.uid, position, tags, attributes: [] })

        this.props.onContactDrop(position, this.desktopView, itemWidth)

        this.props.onUpdateItems(items);

        e.containerElem.style.visibility = "hidden";
    }

    getItems = () => {
        return this.props.items
    }

    resizeDesktop = (event, delta) => {
        this.props.resizeDesktop(event, delta)
    }

    onItemTapped = (item) => {

        let { tag, attributeQuestion } = this.props
        let { lines, activeGroupUid } = this.state

        let check = true;

        if (item.attributes.length && attributeQuestion !== null) {
            item.attributes.map(itemAttr => {
                if (itemAttr.attribute === "Male" && attributeQuestion.index === 2) {
                    check = false;
                }
            })
        }

        if (check) {
            if (item.group) {
                // Grouping stage, draw lines
                if (this.props.pageType !== "bucket") {
                    let activeGroupUid = this.state.activeGroupUid == item.uid ? null : item.uid
                    this.setState({ activeGroupUid })
                }

            }
            else if (attributeQuestion) {
                let items = [...this.props.items]

                let len = items.length
                for (let i = 0; i < len; i++) {
                    if (item.uid == items[i].uid) {

                        item = { ...items[i] }
                        item.attributes = [...item.attributes]

                        let hasAttribute = false
                        for (let y = 0, lenAttributes = item.attributes.length; y < lenAttributes; y++) {
                            if (item.attributes[y].index == attributeQuestion.index) {
                                hasAttribute = true
                                item.attributes.splice(y, 1)
                            }
                        }

                        if (!hasAttribute) {
                            item.attributes.push(attributeQuestion)
                        }

                        items[i] = item

                        this.props.onAttribute(attributeQuestion, item)

                        break
                    }
                }

                this.props.onUpdateItems(items)
            }
            else if (tag) {
                let items = [...this.props.items]

                let len = items.length
                for (let i = 0; i < len; i++) {
                    if (item.uid == items[i].uid) {

                        item = { ...items[i] }
                        item.tags = [...item.tags]

                        let hasTag = false
                        for (let y = 0, lenTags = item.tags.length; y < lenTags; y++) {
                            if (item.tags[y].id == tag.id) {
                                hasTag = true
                                item.tags.splice(y, 1)
                            }
                        }

                        if (!hasTag) {
                            item.tags.push(tag)
                        }

                        items[i] = item

                        break
                    }
                }

                this.props.onUpdateItems(items)
            }
            else if (activeGroupUid != null && this.props.stepGroup) {

                let lines = [...this.state.lines]
                let groups = [...this.props.groups]

                let toKey = item.uid

                for (let i = 0, len = groups.length; i < len; i++) {
                    let group = groups[i]
                    let fromKey = 'group' + group.uid
                    if (group.uid == activeGroupUid) {

                        group.lines = { ...group.lines }
                        if (group.lines[toKey]) {
                            // TODO: why treated like array?...
                            delete group.lines[toKey]
                            for (let y = 0, linesLen = lines.length; y < linesLen; y++) {
                                let line = lines[y]
                                let matchesTo = line.to == toKey
                                let matchesFrom = line.from == fromKey
                                if (matchesTo && matchesFrom) {
                                    lines.splice(y, 1)
                                    break
                                }
                            }
                        }
                        else {
                            // New line
                            let line = {
                                from: 'group' + activeGroupUid,
                                to: toKey,
                                color: group.color
                            }
                            group.lines[toKey] = true
                            lines.push(line)
                        }

                        break
                    }
                }

                // this.setState({ lines, groups })
                this.setState({ lines })
                this.props.onGroupsModified(groups)
            }
            else if (!item.group) {
                if (this.props.onContactTapped) {
                    this.props.onContactTapped(item)
                }
            }
        }



    }

    initializeLines = (groups) => {
        let lines = []

        if (groups) {
            groups.forEach(group => {
                let contactIds = Object.keys(group.lines)

                contactIds.forEach(contactId => {
                    let line = {
                        from: 'group' + group.uid,
                        to: contactId,
                        color: group.color
                    }
                    lines.push(line)
                })

            })
        }

        return lines
    }

    onDrag = (item) => {
        this.setState({})
    }

    swap = (fromIndex, toIndex, dragData) => {
        let items = this.props.items.slice()
        const item = { label: dragData.label, uid: uuidv1() }
        items.splice(toIndex, 0, item)
        this.props.onUpdateItems(item)
    };

    kill = (uid, isGroup) => {
        if (isGroup) {
            this.props.deletedUid(uid)
        } else {
            this.props.updateContactList(uid)
            // this.props.updateContact()
        }
        let items = isGroup ? this.props.groups : this.props.items
        items = [...items]
        const index = items.findIndex((item) => {
            return item.uid == uid
        });
        if (index !== -1) {
            items.splice(index, 1)
        }

        // if (!isGroup) {
        //     this.props.contacts.map((item, key) => {
        //         if (uid === item.uid) {
        //             item.draggable = true;
        //         }
        //     })
        // }

        if (isGroup) {
            this.props.onGroupsModified(items)
        }
        else {
            this.props.onUpdateItems(items)
        }
        this.onContextMenuClose();
    };


    onCreateGroup = () => {
        let groups = [...this.props.groups]

        let group = {
            uid: uuidv1(),
            lines: {},
            type: 'group',
            name: 'unnamed',
            position: { x: 200, y: 0 },
            color: getTagColor(groups.length - 1)
        }

        groups.push(group)
        this.props.onGroupsModified(groups)
    }

    onGroupName = (uid, name) => {
        let groups = [...this.props.groups]

        for (let i = 0, len = groups.length; i < len; i++) {
            let group = groups[i]

            if (group.uid == uid) {

                if (!name) {
                    groups.splice(i, 1)
                }
                else {
                    group = { ...group }
                    group.name = name
                    groups[i] = group
                }

                break
            }
        }

        this.props.onGroupsModified(groups)

    }

    onContextMenuClose = () => {
        this.root.style.visibility = 'hidden'
    }

    onContextMenu = (event, groupRef, uid, isGroup) => {
        this.selectedGroupRef = groupRef
        this.setState({ killUid: uid, isGroup: isGroup })
        // event.preventDefault()

        const clickX = event.clientX;
        const clickY = event.clientY;
        const screenW = window.innerWidth;
        const screenH = window.innerHeight;
        const rootW = this.root.offsetWidth;
        const rootH = this.root.offsetHeight;

        // this.root.style.visibility = 'visible'

        const right = (screenW - clickX) > rootW;
        const left = !right;
        const top = (screenH - clickY) > rootH;
        const bottom = !top;

        if (right) {
            this.root.style.left = `${clickX + 5}px`;
        }

        if (left) {
            this.root.style.left = `${clickX - rootW - 5}px`;
        }

        if (top) {
            this.root.style.top = `${clickY + 5}px`;
        }

        if (bottom) {
            this.root.style.top = `${clickY - rootH - 5}px`;
        }

        this.props.setContextSelectedTeeup(uid)
    };

    onCreateTeeup = () => {

        this.onContextMenuClose()

        if (this.props.onCreateTeeup) {
            this.props.onCreateTeeup()
        }
    }

    onRenameGroup = () => {
        if (this.selectedGroupRef) {
            this.selectedGroupRef.rename()
            this.onContextMenuClose()
        }
        else {
            console.log("No group selected")
        }
    }

    onEditContact = (item) => {
        let boxItem;
        this.props.contactData.map(contact => {
            if (contact.uid === item.uid) {
                boxItem = contact
            }
        })


        this.setState({
            boxItemsModal: this.props.boxItems,
            contactModalOpen: true,
            attr: this.props.attributes,
            id: item.uid,
            label: boxItem.label,
            phone: boxItem.firstNumber,
            name: boxItem.firstName,
            email: boxItem.email.length !== 0 ? boxItem.email[0].email : ''
        })
    }

    onCloseContactModal = () => {
        this.setState({
            contactModalOpen: false,
            boxItemsModal: [],
            attr: [],
        })
    }

    renderContextMenu = () => {

        return <div ref={ref => { this.root = ref }} className="context-menu">
            <div className="overlay" onClick={this.onContextMenuClose} />
            <div className="contextMenu--list">
                {/* <div className="contextMenu--option" onClick={this.onCreateTeeup}>Create TeeUp</div> */}
                {
                    this.menuItems()
                }
            </div>
        </div>
    }

    menuItems = () => {
        if (this.state.isGroup) {
            return <div className="contextMenu--option" onClick={this.onRenameGroup}>Rename group</div>
        }
        else
            return <div className="contextMenu--option" onClick={() => this.kill(this.state.killUid, false)}>Delete</div>
    }

    componentDidMount() {
        this.setState({
            minDesktopWidth: this.desktopView.offsetWidth,
            boxItemsModal: this.props.boxItems,
            attr: this.props.attributes,
            pageType: this.props.pageType,
            current: this.props.current

        })

    }

    static getDerivedStateFromProps(nextProps, state) {
        let updates = null

        if (nextProps.pageType !== state.pageType) {
            updates = {
                pageType: nextProps.pageType
            }
        }

        return updates
    }

    componentDidUpdate() {
        if (this.props.current !== this.state.current) {
            this.setState({ current: this.props.current })
            this.setState({ activeGroupUid: null })
        }
    }

    deleteGroup= (group) => {
        console.log('group', group)
        this.props.deleteGroup(group)
        //delete lines
        const filteredLines = this.state.lines.filter(line => line.from !== 'group'+group.uid)
        this.setState({
            lines:filteredLines
        })
    }

    render() {
        /*
            Note the two layers of DropTarget. 
            This enables it to handle dropped items from 
            outside AND items dragged between boxes.
        */

        let { lines, itemHeight, activeGroupUid, minDesktopWidth, boxItemsModal, attr, pageType } = this.state


        let { tag, tags, attributeQuestion, bucket, bucketSelectedContacts,
            showCreateGroup, groups, items, finishToAddContact, attributes, boxItems, current } = this.props

        let allTags = {}
        for (let i = 0, len = tags.length; i < len; i++) {
            allTags[tags[i].id] = tags[i]
        }

        let renderedItems = items.map((item, index) => {
            const contextMenu = < Menu >
                <Menu.Item
                    key="delete-contact"
                    onClick={() => this.onEditContact(item)}
                >
                    Edit
                </Menu.Item>
                <Menu.Item
                    key="delete-contact"
                    onClick={() => this.kill(this.state.killUid, false)}
                >
                    Delete
                </Menu.Item>
            </Menu >

            return <Dropdown
                key={index}
                overlay={contextMenu}
                trigger={['contextMenu']}
            >
                <BoxItem
                    ref={index == 0 && !itemHeight ? (element) => {
                        if (!element) {
                            return
                        }
                        var rect = ReactDOM.findDOMNode(element)
                            .getBoundingClientRect()
                        this.setState({ itemHeight: rect.height, itemWidth: rect.width })
                    } : undefined}
                    item={item}
                    position={item.position}
                    draggable
                    callback={(e, ref) => this.onContextMenu(e, ref, item.uid, false)}
                    key={item.uid}
                    uid={item.uid}
                    kill={this.kill}
                    index={index}
                    swap={this.swap}
                    alreadySelected={bucketSelectedContacts[item.uid]}
                    name={item.label}
                    tags={item.tags}
                    allTags={allTags}
                    attributes={item.attributes}
                    image={''}
                    activeAttributeIndex={attributeQuestion ? attributeQuestion.index : -1}
                    activeTagId={tag ? tag.id : 0}
                    onItemTapped={this.onItemTapped}
                    onDrag={this.onDrag}
                    onDragStop={this.onDragStop}
                />
            </Dropdown>
        })

        let renderedGroups = groups.map((group, index) => {

            let contextMenu = < Menu >
                <Menu.Item
                    key="rename-group"
                    onClick={this.onRenameGroup}
                >
                    Rename
                </Menu.Item>
            </Menu>

            if (pageType === 'teeup') {
                contextMenu = < Menu >
                    <Menu.Item
                        key="create-teeup"
                        onClick={() => {
                            this.props.onCreateTeeup(group)
                        }
                        }
                    >
                        Create a TeeUp
                    </Menu.Item>
                    <Menu.Item
                        key="delete-group"
                        onClick={() => {this.deleteGroup(group)
                        }
                        }
                    >
                        Delete Group
                    </Menu.Item>
                </Menu>
            }

            return <Dropdown
                key={index}
                overlay={contextMenu}
                trigger={['contextMenu']}
            >
                <GroupItem
                    current={current}
                    groupItem={group}
                    typed={group.name}
                    position={group.position}
                    key={group.uid}
                    uid={group.uid}
                    kill={this.kill}
                    swap={this.swap}
                    groupColor={group.color}
                    group
                    index={index}
                    onItemTapped={this.onItemTapped}
                    activeGroupUid={activeGroupUid}
                    onDrag={this.onDrag}
                    callback={(e, ref) => this.onContextMenu(e, ref, group.uid, true)}
                    onGroupName={this.onGroupName}
                    onDragStop={this.onDragStopGroup}
                    pageType={pageType}
                />
            </Dropdown>
        })

        let renderedLines = lines.map((line, index) => {
            return <LineTo borderColor={line.color} borderWidth={2} key={index} zIndex={-100} from={line.from} to={line.to} />
        })
        return (
            <div className="box_container_desktop" >
                <div className="box_title font-demibold-header">Desktop</div>

                <div className='flex-container'>
                    <div className="box_desktop" ref={ref => this.desktopView = ref} >
                        <Rnd
                            size={{ width: this.state.width, height: this.state.height }}
                            onResize={(e, direction, ref, delta, position) => {
                                // this.setState({
                                //     width: parseInt(ref.style.width , 10) + delta.width*2,
                                // });
                                this.resizeDesktop(e, delta)
                            }}
                            style={{
                                display: 'flex', flex: 1, position: 'relative'
                            }}
                            // enableResizing={{
                            //     top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false
                            // }}
                            disableDragging={true}
                            minWidth={minDesktopWidth}
                        >
                            {/* <LineTo zIndex={-100} from="group0" to="contact0" />
                <LineTo zIndex={-100} from="contact1" to="contact0" /> */}
                            {renderedLines}
                            <DropTarget
                                onHit={this.handleDrop}
                                targetKey="boxItem"
                                dropData={{ name: this.props.name }}
                            >
                                {showCreateGroup && <div className="rounded-button bcolor-pink create-group-button" onClick={this.onCreateGroup} style={{ zIndex: 500 }}>
                                    <span className="rounded-button-text">Create Group</span>
                                </div>}
                                {renderedGroups}
                                {renderedItems}
                            </DropTarget>

                            {this.renderContextMenu()}
                        </Rnd>

                    </div>
                    <div className='silent-placeholder' />
                </div>
                <ContactModal
                    tags={tags}
                    boxItems={boxItemsModal}
                    attributes={attributes}
                    activeTagId={tag ? tag.id : 0}
                    allTags={allTags}
                    modalOpened={this.state.contactModalOpen}
                    onCloseModal={this.onCloseContactModal}
                    id={this.state.id}
                    label={this.state.label}
                    name={this.state.name}
                    email={this.state.email}
                    phone={this.state.phone}
                    finishToAddContact={finishToAddContact}

                />
            </div>
        )
    }
}