import React, { Component } from 'react'
import { connect } from "react-redux"
import BasicsView from './BasicsView'

import activitiesActions from 'actions/activitiesActions'
import loginActions from 'actions/loginActions'
import stepActions from 'actions/stepActions'
import { stepIds } from 'configs/enums'

import { bindActionCreators } from 'redux'

class BasicsContainer extends Component {

    writeValue = (event, param) => {
        this.setState({
            [param]: event.target == undefined ? '' : event.target.value
        })
    }

    multipleSelect = (options, param) => {
        this.setState({
            [param]: options.map(o => o.value)
        })
    }

    changeLocation = (address, param) => {
        this.setState({ [param]: address })
    }

    handleSelect = (address, param) => {
        this.setState({ [param]: address })
    };

    checkAge = (event) => {
        if (event.target.value != '' && event.target.value < 18) {
            alert("You must be atleast 18 years old to be part of this study. You will be redirected to login screen.");
            setTimeout(() => {
                // After 5 seconds show login screen
                this.props.history.replace('/login')
            }, 1000)
        }
    }

    constructor(props) {
        super(props)

        props.stepActions.setActiveStep(stepIds.demographics)

        props.loginActions.fetchContacts()
        props.activitiesActions.fetchDemographicsMeta()

        this.answers = props.answers

        if (!this.answers || !this.answers.age) {
            this.answers = {
                age: '',
                gender: '',
                orientation: '',
                growUpCity: '',
                liveCity: '',
                primaryLanguage: 'English',
                speaksAdditionalLanguage: 'false',
                otherLanguages: [],
                relationshipStatus: '',
                studentStatus: '',
                majoring: '',
                internationalStudent: '',
                outofstateStudent: '',
                address1: ''
            }
        }

        this.state = this.answers
    }

    static getDerivedStateFromProps(nextProps, state) {
        let updates = null
        if (nextProps.answers !== state.answers) {
            if (state.age === '') {
                updates = { ...nextProps.answers }
            } else {
                updates = { ...state.answers }
            }
        }

        return updates
    }

    onNext = async () => {
        await this.props.activitiesActions.sendBasicsQuestions(this.state)
        await this.props.history.push('activities/selector')
    }

    render() {
        const {
            age, growUpCity, liveCity, gender, orientation, primaryLanguage, otherLanguages, relationshipStatus
            , studentStatus, majoring, internationalStudent, outofstateStudent, speaksAdditionalLanguage, address1
        } = this.state


        let isValid = age.length > 0 && age.length < 100 &&
            gender.length > 0 &&
            growUpCity.length > 0 &&
            liveCity.length > 0 &&
            primaryLanguage.length > 0 &&
            relationshipStatus.length > 0 &&
            studentStatus.length > 0 &&
            majoring.length > 0

        const { majors, languages } = this.props
        return (
            <BasicsView
                age={age}
                growUpCity={growUpCity}
                liveCity={liveCity}
                gender={gender}
                orientation={orientation}
                primaryLanguage={primaryLanguage}
                relationshipStatus={relationshipStatus}
                studentStatus={studentStatus}
                majoring={majoring}
                internationalStudent={internationalStudent}
                outofstateStudent={outofstateStudent}
                writeValue={this.writeValue}
                checkAge={this.checkAge}
                otherLanguages={otherLanguages}
                multipleSelect={this.multipleSelect}
                onNext={isValid ? this.onNext : undefined}
                speaksAdditionalLanguage={speaksAdditionalLanguage}

                languages={languages}
                majors={majors}

                address={address1}
                changeLocation={this.changeLocation}
                handleSelect={this.handleSelect}

            />
        )
    }
}


const mapStateToProps = (state) => ({
    languages: state.data.languages,
    majors: state.data.majors,
    answers: state.data.demographics,
})

const mapDispatchToProps = (dispatch) => ({
    activitiesActions: bindActionCreators(activitiesActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
    stepActions: bindActionCreators(stepActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicsContainer)